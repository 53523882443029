import { ArrowLeftOutlined } from "@ant-design/icons";
import { Divider, Input } from "antd";
import { observer, useObserver } from "mobx-react";
import React, { useEffect, useState } from "react";
import useGetPoints from "../../hooks/useGetPoints";
import useGetTasks from "../../hooks/useGetTasks";
import { getReportCount } from "../../service/api";
import { detailStore } from "../../store/detailStore";
import { plantStore } from "../../store/plantStore";
import { ReportCount, reportStore } from "../../store/reportStore";
import { taskStore } from "../../store/taskStore";
import { Loading } from "../Loading/Loading";
import TaskList from "../TaskList";
import "./PlantInfoSider.scss";

import PowerStationSVG from "../../assets/svg/power-station.svg";

export const PlantInfoSider = observer(() => {
	const { setCurrentPlant, currentPlant } = plantStore;
	const { setReportCount, reportCount } = reportStore;
	const { TextArea } = Input;
	const {
		setCurrentTask,
		setCurrentPoint,
		currentTask,
		setPoints,
		setDefects,
		setFilterPointsStatus,
		defects,
		points,
	} = taskStore;
	const [taskShareUrl, setTaskShareUrl] = useState("");

	const getTaskLoading = useGetTasks();
	const getPointsLoading = useGetPoints();
	// const [defectPointsCount, setDefectPointsCount] = useState(0)

	useEffect(() => {
		setCurrentPoint(null);
	}, [setCurrentPoint, currentTask]);

	// useEffect(() => {
	//   if (points.length && currentTask) {
	//     const count = points.filter(_item => defects.some(item => _item.infrared_image_name === item.name || _item.light_image_name === item.name)).length
	//     setDefectPointsCount(count)
	//   }
	// }, [defects, points, currentTask])

	useEffect(() => {
		const getCurrentTaskReportCount = async () => {
			const reportCount: ReportCount = await getReportCount(currentTask!.id);
			setReportCount(reportCount);
		};
		if (currentTask) {
			getCurrentTaskReportCount();
		}
	}, [currentTask, setReportCount]);

	const goBack = () => {
		//!恢复初始值
		setCurrentPlant(null);
		setCurrentTask(null);
		setPoints([]);
		setDefects([]);
		setCurrentPoint(null);
		setFilterPointsStatus(8);
	};
	if (getPointsLoading) {
		return useObserver(() => <Loading content="正在获取任务航点以及缺陷..." />);
	}
	if (getTaskLoading) {
		return useObserver(() => <Loading content="正在获取任务..." />);
	}

	return useObserver(() => (
		<div className="plant-info-sider-wrapper">
			<div className="plant-info-header">
				<div className="plant-back" onClick={goBack}>
					<ArrowLeftOutlined />
					<div>返回列表</div>
				</div>
				<Divider style={{ margin: 0, backgroundColor: "#fff" }} />
				<div className="plant-info">
					<h2>{currentPlant!.name}</h2>
					<p>
						<span className="p-title">经度</span>
						<span className="p-value">{currentPlant!.longitude}</span>
					</p>
					<p>
						<span className="p-title">纬度</span>
						<span className="p-value">{currentPlant!.latitude}</span>
					</p>
					{/* {currentTask && ( */}
					{/*   <p> */}
					{/*     <span className="p-title">缺陷航点/全部航点数</span> */}
					{/*     <span className="p-value"> */}
					{/*       {reportCount.has_defect_num}/{" "} */}
					{/*       {reportCount.has_defect_num + reportCount.no_defect_num} */}
					{/*     </span> */}
					{/*   </p> */}
					{/* )} */}
					{taskShareUrl && (
						<TextArea value={taskShareUrl} style={{ color: "#fff" }} />
					)}
				</div>
				<img
					src={PowerStationSVG}
					alt="power-station"
					width={85}
					height={85}
					style={{
						position: "absolute",
						top: "59px",
						right: "31px",
						opacity: 0.1,
					}}
				/>
			</div>
			<TaskList
				taskStore={taskStore}
				detailStore={detailStore}
				plantStore={plantStore}
				setTaskShareUrl={setTaskShareUrl}
			/>
		</div>
	));
});
