import { Divider, Menu } from "antd";
import { observer, useObserver } from "mobx-react";
import React from "react";
import useGetPlants from "../../hooks/useGetPlants";
import { companyStore } from "../../store/companyStore";
import { plantStore } from "../../store/plantStore";
import { PlantInfo } from "../../typings/interface";
import Loading from "../Loading";
import "./PlantListSider.scss";

import barchartPNG from "../../assets/image/barchart.png";
import clockPNG from "../../assets/image/clock.png";
import solarPNG from "../../assets/image/solar.png";

export const PlantListSider = observer(() => {
  const { plants, setCurrentPlant } = plantStore;
  const { patrolCount } = companyStore;
  //获取 所有电站
  const getPlantsLoading = useGetPlants();

  if (getPlantsLoading) {
    return useObserver(() => <Loading content="正在获取电站..." />);
  }
  const toPlantInfo = (item: PlantInfo) => {
    setCurrentPlant(item);
  };
  console.log(patrolCount);
  return useObserver(() => (
    <div className="plant-list-sider-wrapper">
      <div className="plant-card">
        <div className="card-item">
          <div className="card-icon">
            <img src={barchartPNG} alt="barchart" width={32} height={32} />
          </div>
          <div className="card-right">
            <p className="card-title">电站总数</p>
            <p className="card-data">{plants.length}</p>
          </div>
        </div>
        <Divider style={{ margin: "12px 0" }} />
        <div className="card-item">
          <div className="card-icon">
            <img src={solarPNG} alt="solar" width={32} height={32} />
          </div>
          <div className="card-right">
            <p className="card-title">最近巡检电站</p>
            <p className="card-data">{patrolCount.project_name || ""}</p>
          </div>
        </div>
        <Divider style={{ margin: "12px 0" }} />
        <div className="card-item">
          <div className="card-icon">
            <img src={clockPNG} alt="clock" width={32} height={32} />
          </div>
          <div className="card-right">
            <p className="card-title">数据上传时间</p>
            <p className="card-data">{patrolCount.patrol_date || ""}</p>
          </div>
        </div>
      </div>
      <Menu className="plants-menu" theme="light" mode="inline">
        {plants.map((item, index) => (
          <Menu.Item key={item.id}>
            <div
              className="plant-item-wrapper"
              onClick={() => toPlantInfo(item)}
            >
              <div className="item-order">{index + 1}</div>
              <div className="item-details">
                <p className="item-name">{item.name}</p>
                <p className="item-time">{item.create_time}</p>
              </div>
            </div>
          </Menu.Item>
        ))}
      </Menu>
      {/* <div className="plant-count"> */}
      {/*   <p> */}
      {/*     <span>电站总数</span> */}
      {/*     <span>{plants.length}</span> */}
      {/*   </p> */}
      {/*   <p> */}
      {/*     <span> 最近巡检电站</span> */}
      {/*     <span>{patrolCount.project_name || ""}</span> */}
      {/*   </p> */}
      {/*   <p> */}
      {/*     <span>数据上传时间</span> */}
      {/*     <span>{patrolCount.patrol_date || ""}</span> */}
      {/*   </p> */}
      {/* </div> */}
    </div>
  ));
});
