import { action, makeObservable, observable } from 'mobx'
import { UsersInfo } from '../typings/interface'

class AppState {
  constructor() {
    makeObservable(this)
  }
  @observable user: UsersInfo | null = null

  @observable token: string | null = null

  @action setUser = (userInfo: UsersInfo | null) => {
    this.user = userInfo
  }

  @action setToken = (token: string | null) => {
    this.token = token
  }
}

export const appStore = new AppState()
