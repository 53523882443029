import { action, makeObservable, observable } from 'mobx'
import { Defect, DefectTypes, PlantInfo, PointInfo, TaskInfo } from '../typings/interface'
export interface ReportCount {
  count_by_stats_type: any[]
  count_by_status: any
  count_by_type: any
  has_defect_num: number
  no_defect_num: number
}
class DetailStore {
  constructor() {
    makeObservable(this)
  }
  @observable detailCurrentPlant: PlantInfo | null = null
  @observable detailToken: string | null = ''
  @observable detailCurrentTask: TaskInfo | null = null //当前任务
  @observable detailCurrentPoint: PointInfo | null = null //当前航点
  @observable detailPoints: PointInfo[] = []
  @observable detailFilterPointsStatus: number = 8
  @observable detailDefects: Defect[] = []
  @observable detailMap: any | null = null
  @observable detailGoogleMapAvailable: boolean = true
  @observable detailDefectTypes: any[] = [] // 缺陷类型
  @observable detailReportCount: ReportCount | any = {}
  @observable detailFilterPointsName: string = ''
  @observable detailMiniMap: boolean = false //切换
  @observable detailInfraredUrl: string = "" 
  @observable detailLightUrl: string = "" 
  
  @observable detailInfraredInfo: any = null
  @observable detailLightInfo: any = null
  
  @action setDetailInfraredInfo = (info: any) => {
    this.detailInfraredInfo = info
  }
  @action setDetailLightInfo = (info: any) => {
    this.detailLightInfo = info
  }
  @action setDetailInfraredUrl = (url: string) => {
    this.detailInfraredUrl = url
  }
  @action setDetailLightUrl = (url: string) => {
    this.detailLightUrl = url
  }

  @action setDetailMiniMap = (flag: boolean) => {
    this.detailMiniMap = flag
  }



  @action setDetailReportCount = (reportCount: ReportCount) => {
    this.detailReportCount = reportCount
    console.log('%cstate:已更新detailReportCount对象::', 'color:blue', { reportCount })
  }
  @action setDetailGoogleMapAvailable = (status: boolean) => {
    this.detailGoogleMapAvailable = status
  }

  @action setDetailMap = (map: any) => {
    this.detailMap = map
  }
  // setDetailCurrentPoint, detailCurrentTask, detailPoints, detailDefects, detailCurrentPoint, detailFilterPointsStatus, setDetailFilterPointsStatus
  @action setDetailCurrentPoint = (currentPoint: PointInfo | null) => {
    this.detailCurrentPoint = currentPoint
    console.log('%cstate:已更新detailCurrentPoint对象::', 'color:blue', { currentPoint })
  }
  @action setDetailPoints = (points: PointInfo[]) => {
    this.detailPoints = points
    console.log('%cstate:已更新detailPoints对象::', 'color:blue', { points })
  }
  @action setDetailFilterPointsStatus = (status: number | any) => {
    this.detailFilterPointsStatus = status
    console.log('%cstate:已更新detailFilterPointsStatus对象::', 'color:blue', { status })
  }
  @action setDetailFilterPointsName = (name: string | any) => {
    this.detailFilterPointsName = name
    console.log('%cstate:已更新detailFilterPointsName对象::', 'color:blue', { name })
  }
  @action setDetailDefects = (defects: Defect[]) => {
    this.detailDefects = defects
    console.log('%cstate:已更新detailDefects对象::', 'color:blue', { defects })
  }
  @action setDetailCurrentTask = (currentTask: TaskInfo | null) => {
    this.detailCurrentTask = currentTask
    console.log('%cstate:已更新detailCurrentTask对象::', 'color:blue', { currentTask })
  }

  @action setDetailCurrentPlant = (Plant: PlantInfo | null) => {
    this.detailCurrentPlant = Plant
  }

  @action setDetailToken = (token: string) => {
    this.detailToken = token
  }
  @action setDetailDefectTypes = (types: DefectTypes[]) => {
    this.detailDefectTypes = types
    console.log('%cstate:已更新detailDefectTypes对象::', 'color:blue', { types })
  }
}

export const detailStore = new DetailStore()
