import React from "react";
import { appStore } from "../../store/appStore";
import { CompanyLogo, CompanyLogoProps } from "./CompanyLogo";
import "./Login.scss";
import { LoginForm } from "./LoginForm";

const customCompany: CompanyLogoProps = {
  companyName: "",
  logoUrl: "",
  subtitle: "",
};

export const Login = () => {
  return (
    <div className="login-wrapper">
      <div className="login-container">
        {/* <CompanyLogo {...customCompany} /> */}
        <LoginForm
          companyName={customCompany.companyName}
          appStore={appStore}
        />
      </div>
    </div>
  );
};
