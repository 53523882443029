import {
  BillboardGraphics,
  Cartesian3,
  ConstantProperty,
  Entity,
  LabelGraphics,
  Math as CesiumMath,
  Rectangle,
  SceneTransforms,
} from "cesium";
import mapNailRoute from "../../assets/svg/map-nail.svg";
import { POINT_STATUS_CESIUM_COLOR } from "../../assets/js/constants";
import { transform } from "../../assets/js/methods";
import {
  Defect,
  EntityCategory,
  PlantInfo,
  PointInfo,
  PvCesiumPoint,
} from "../../typings/interface";
import CesiumMap from "./CesiumMap";

// 含状态的点生成器
export const statusPointsGenerator = (
  points: PointInfo[] = [],
  defects: Defect[] = [],
  filterPointsStatus?: number,
) => {
  // 每个点上的缺陷map
  // 每个点上的缺陷map
  let pointsList = renderSelectDefect(points, defects, filterPointsStatus!);
  return (
    pointsList &&
    pointsList.map((item: any) => {
      // let position = transform(+item.longitude, +item.latitude);
      let position = [Number(item.longitude), Number(item.latitude)]
      let pointDefectList = [];
      pointDefectList = defects.filter(
        (defect) => defect.patrol_point_id === item.id,
      );
      const updatePoint: PvCesiumPoint = {
        ...item,
        position: Cartesian3.fromDegrees(position[0], position[1], 2),
        count: pointDefectList.length,
        defects: pointDefectList,
        color: item.color
          ? item.color
          : getStatusColors(+item.status, pointDefectList.length),
      };

      return updatePoint;
    })
  );
};

export const renderSelectDefect = (
  points: PointInfo[] = [],
  defects: Defect[] = [],
  type: number | string,
) => {
  let list: any[] = [];
  switch (type) {
    case 8:
      list = points; //全部航点
      break;
    case 9:
      list = renderDefectPoints(points, defects); //缺陷航点//!现通过接口
      break;
    case 10:
      list = renderNotDefectPoints(points, defects); //无缺陷航点 //!现通过接口
      break;
    // case 11:
    //   list = renderEditDefectPoints(points, defects) //编辑航点
    //   break
    default:
  }
  //!现通过接口
  if (
    type === 1 ||
    type === 2 ||
    type === 3 ||
    type === 4 ||
    type === 5 ||
    type === 6 ||
    type === 7
  ) {
    list = renderDefectType(points, defects, type); //缺陷类型
  }
  if (typeof type === "string") {
    list = renderDefectImageName(points, type);
  }
  let newList = JSON.parse(JSON.stringify(list));
  if (newList.length) {
    return (
      newList.sort(
        (c: any, d: any) => c.name.split("-")[3] - d.name.split("-")[3],
      ) || []
    );
  } else {
    return [];
  }
};

export const renderDefectImageName = (
  points: PointInfo[] = [],
  name: string,
) => {
  let selectList = [];
  selectList = points.filter(
    (ite) =>
      ite.infrared_image_name.includes(name) ||
      ite.light_image_name.includes(name),
  );
  return selectList;
};

//类型筛选
export const renderDefectType = (
  points: PointInfo[] = [],
  defects: Defect[] = [],
  type: number,
) => {
  let selectList: PointInfo[] = [];
  defects.forEach((defect) => {
    let newArr = points.filter(
      (ite) =>
        ite.id === defect.patrol_point_id && defect.defect_type.id === type,
    );
    newArr.forEach((_item) => {
      if (
        !selectList.find(
          (items) =>
            items.infrared_image_name === defect.name ||
            items.light_image_name === defect.name,
        )
      ) {
        selectList.push(_item);
      }
    });
  });
  return selectList;
};

//渲染有缺陷的数据
export const renderDefectPoints = (
  points: PointInfo[] = [],
  defects: Defect[] = [],
) => {
  return points.filter((_item) =>
    defects.some(
      (item) =>
        _item.infrared_image_name === item.name ||
        _item.light_image_name === item.name,
    ),
  );
};

//渲染没有缺陷的数据
export const renderNotDefectPoints = (
  points: PointInfo[] = [],
  defects: Defect[] = [],
) => {
  let selectList = [...points];
  defects.forEach((item) => {
    let index = selectList.findIndex((_item) => {
      return (
        _item.infrared_image_name === item.name ||
        _item.light_image_name === item.name
      );
    });
    if (index >= 0) {
      selectList.splice(index, 1);
    }
  });
  return selectList;
};

// //渲染编辑缺陷的数据
// export const renderEditDefectPoints = (points: PointInfo[] = [], defects: Defect[] = []) => {
//   let selectList: PointInfo[] = []
//   defects.forEach(defect => {
//     let newArr = points.filter(ite => ite.infrared_image_name === defect.name && ite.light_image_name === defect.name)
//     newArr.forEach(_item => {
//       selectList.unshift(_item)
//     })
//   })
//   return selectList
// }

export function initDefaultMap(map: CesiumMap) {
  let defaultCenter = Cartesian3.fromDegrees(112.8, 34.5, 14000000);
  map.setViewer(defaultCenter);
}
export const getStatusColors = (status: number, count: number): any => {
  if (status === 0 && count !== null && count > 0) {
    return "#e4393c";
  }
  if (status === 3) {
    return "#dcbe52";
  }
  if (status > POINT_STATUS_CESIUM_COLOR.size) {
    return "#9f9f9f";
  }
  return POINT_STATUS_CESIUM_COLOR.get(status);
};

/**
 * 绘图：地球上，电站撒点
 * @param {{name:string, address:[number,number]}[]} points
 * @param {*} viewer cesium viewer实例对象
 */
export function setPlantEntities(
  plants: PlantInfo[],
  viewer: CesiumMap["viewer"],
) {
  if (!viewer) {
    return console.error("传入viewer异常");
  }

  for (const item of plants) {
    if (!item || !item.longitude || !item.latitude) {
      console.warn("电站地理位置异常", item);
      continue;
    }
    // let position = transform(+item.longitude, +item.latitude);
    let position = [Number(item.longitude), Number(item.latitude)]
    const plant = new Entity({
      id: item.id + "",
      name: "plant",
      position: Cartesian3.fromDegrees(position[0], position[1], 10),
      billboard: new BillboardGraphics({
        image: new ConstantProperty(mapNailRoute),
        scale: new ConstantProperty(0.5),
      }),
      description: "plant", // 储存电站数据
      label: new LabelGraphics({
        text: `${item.name}`,
        font: "10pt Source Han Sans CN",
        pixelOffset: new Cartesian3(0, 20, 0),
      }),
    } as any);
    viewer.entities.add(plant);
  }
}

/**
 * 绘图：计算得到多个电站坐标的中心
 * @export
 * @param {PlantGISPoint[]} plants
 * @returns {[number, number]}
 */
export function calcCenterOfPlants(plants: PlantInfo[]): [number, number] {
  const lons = plants
    .map((item) => +item.longitude)
    .filter((lon) => !Number.isNaN(lon));
  const lats = plants
    .map((item) => +item.latitude)
    .filter((lat) => !Number.isNaN(lat));

  // 视口中心点
  const center: [number, number] = [
    (Math.max(...(lons as number[])) + Math.min(...(lons as number[]))) / 2,
    (Math.max(...(lats as number[])) + Math.min(...(lats as number[]))) / 2,
  ];
  return center;
}

/**
 * 绘图：计算距中心点最远距离
 * @param {{name:string, address:[number,number]}[]} plants
 * @param {[number,number]}  center 地图中心点
 */
export function calcRectPoints(plants: PlantInfo[], center: [number, number]) {
  const adds = plants.map((item) => [item.longitude, item.latitude]);
  let farthest = 0;
  let pointIndex = 0;
  adds.forEach((item, index) => {
    if (!item[0] || !item[1]) {
      return;
    }
    const distance = Math.sqrt(
      Math.pow(item[0] - center[0], 2) + Math.pow(item[1] - center[1], 2),
    );
    if (distance > farthest) {
      farthest = distance;
      pointIndex = index;
    }
  });

  if (!adds[pointIndex]) {
    return;
  }
  const delta = {
    x: Math.abs(adds[pointIndex][0]! - center[0]),
    y: Math.abs(adds[pointIndex][1]! - center[1]),
  };

  const n = center[1] + delta.y;
  const s = center[1] - delta.y;
  const w = center[0] - delta.x;
  const e = center[0] + delta.x;
  const result: [number, number, number, number] = [n, s, w, e];
  return result;
}

// 移动默认视角
export function plantCameraCentral(plants: PlantInfo[]): Rectangle {
  const center = calcCenterOfPlants(plants);
  const boundary = calcRectPoints(plants, center);
  const [n, s, w, e] = boundary!;
  const viewRect = new Rectangle(
    CesiumMath.toRadians(w - 0.75),
    CesiumMath.toRadians(s - 0.75),
    CesiumMath.toRadians(e + 0.75),
    CesiumMath.toRadians(n + 0.75),
  );
  return viewRect;
}

/**
 * 依据名称, 得到所有该类实例
 * (从cesium实例的description.getValue中获得)
 * @export
 * @param {CesiumMap} map
 * @param {EntityCategory} [type] 未传入时返回所有
 * @param {EntityCategory[]} [excludes] 传入时排除列表内的所有
 * @returns
 */
export function getSpecificEntities(
  entities: Entity[],
  type?: EntityCategory,
  excludes?: EntityCategory[],
) {
  if (type) {
    const updateEntities = entities.filter(
      (entity) => entity.description && entity.description.getValue() === type,
    );
    return updateEntities;
  }
  if (excludes) {
    const updateEntities = entities.filter((entity) => {
      const val = entity.description && entity.description.getValue();
      return !excludes.find((ex: string) => ex === val);
    });
    return updateEntities;
  }
  return entities;
}

export function modelFixDom(domId: any, mapRef: any, entity: any) {
  // var entity: any = mapRef.current!.viewer!.entities.getById(domId)
  if (!entity || !entity.position) return;
  var cartesian = entity.position._value;
  if (!cartesian) return;
  var dom: any = document.getElementById(domId);

  var winPosi: any = transPosition(cartesian, mapRef);
  if (winPosi !== undefined && winPosi !== null && dom !== null) {
    var res = enableShow(cartesian, mapRef);

    if (res) {
      dom.style.left = parseInt(winPosi.x) + "px";
      dom.style.top = parseInt(winPosi.y) + "px";
      dom.style.display = "";
    } else {
      dom.style.display = "none";
    }
  }
}

export function enableShow(cartesian: any, mapRef: any) {
  var res = false;
  var e = cartesian,
    f = mapRef.current!.viewer!.scene.camera.position,
    g =
      mapRef.current!.viewer!.scene.globe.ellipsoid.cartesianToCartographic(
        f,
      ).height;
  if (
    !((g += 1 * mapRef.current!.viewer!.scene.globe.ellipsoid.maximumRadius),
      Cartesian3.distance(f, e) > (g * 4) / 5)
  ) {
    res = true;
  }
  return res;
}

//三维笛卡尔坐标转屏幕坐标
export function transPosition(position: any, mapRef: any) {
  return SceneTransforms.wgs84ToWindowCoordinates(
    mapRef.current!.viewer!.scene,
    position,
  );
}
