import { action, makeObservable, observable } from 'mobx'
import { PATROL_COUNT } from '../assets/js/constants'

class CompanyStore {
  constructor() {
    makeObservable(this)
  }

  @observable patrolCount: any = PATROL_COUNT // 当前公司最新巡检信息
  @action setPatrolCount = (patrolCount: any) => {
    this.patrolCount = patrolCount
    console.log('%cstate:已更新patrolCount对象::', 'color:blue', { patrolCount })
  }
}

export const companyStore = new CompanyStore()
