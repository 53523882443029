import { Button } from 'antd'
import { observer, useObserver } from 'mobx-react'
import React, { useState } from 'react'
import { connectionStore } from '../../store/connectionStore'
import FlvLive from '../FlvLive'
import './LiveView.scss'

export interface FlvStreamPayload {
  id: number
  name: string
  url: string
}
//`http://139.196.75.44:8709/live?port=1935&app=live&stream=test`
export const LiveView = observer(() => {
  const { liveRecordsUrl } = connectionStore

  const droneStream: FlvStreamPayload = {
    id: 1,
    url: `${liveRecordsUrl ? liveRecordsUrl.flv_link : ''}`,
    name: 'drone'
  }

  const [fold, setFold] = useState(false)

  return useObserver(() => (
    <div className="live-view-out-wrapper">
      <Button className="live-folder" onClick={() => setFold(s => !s)} ghost={true}>
        关闭直播窗口
      </Button>
      <div className={`live-view-wrapper ${fold ? 'fold' : ''}`}>
        <div className="live-content drone-live">
          <FlvLive liveOptions={droneStream} />
        </div>
      </div>
    </div>
  ))
})
