import { LoadingOutlined } from "@ant-design/icons";
import { observer, useObserver } from "mobx-react-lite";
import React, { useEffect, useRef, useState } from "react";
import { getPointDefects } from "../../service/api";
import { detailStore } from "../../store/detailStore";
import { reportStore } from "../../store/reportStore";
import { getInfraredUrl, paintCanvas } from "../HistoryBanner/methods";
import Loading from "../Loading";
import "./DetailHistory.scss";
const thumbWidth = 192;
const thumbHeight = 154;
export const DetailHistory = observer(() => {
  const {
    setPointDefects,
    pointDefects,
    setHistoryPointDefects,
    historyPointDefects,
  } = reportStore;
  const {
    detailCurrentPoint,
    detailCurrentTask,
    detailCurrentPlant,
    detailInfraredUrl,
    detailLightUrl,
    detailInfraredInfo,
    detailLightInfo,
    setDetailInfraredInfo,
    setDetailLightInfo,
    setDetailInfraredUrl,
    setDetailLightUrl,
  } = detailStore;
  const infraredRef = useRef<HTMLImageElement>(null);
  const lightRef = useRef<HTMLImageElement>(null);

  const reportCanvasRef = useRef<HTMLCanvasElement>(null);
  const reportLightRef = useRef<HTMLCanvasElement>(null);

  const [reportLoading, setReportLoading] = useState(false);
  const [infraredLoading, setInfraredLoading] = useState(false);
  const [lightLoading, setLightLoading] = useState(false);

  const [taskHistoryLoading, setTaskHistoryLoading] = useState(false);

  useEffect(() => {
    const getCurrentPointDefects = async () => {
      const pointDefects = await getPointDefects(
        detailCurrentTask!.id,
        detailCurrentPoint!.id
      );
      setPointDefects(pointDefects);
      setReportLoading(false);
    };
    if (detailCurrentPoint && detailCurrentTask) {
      setReportLoading(true);
      getCurrentPointDefects();
    }
  }, [detailCurrentPoint, detailCurrentTask, setPointDefects]);

  useEffect(() => {
    if (pointDefects) {
      getInfraredUrl(
        pointDefects,
        detailCurrentPlant!.id,
        detailCurrentTask!.id
      ).then((res) => {
        const { infraredImage, lightImage, infraredUrl, lightUrl } = res;
        setDetailInfraredInfo(infraredImage);
        setDetailLightInfo(lightImage);
        const imagePackage = {
          detailInfraredInfo: infraredImage,
          detailLightInfo: lightImage,
        };
        console.log({ imagePackage });
        // setImageInfos(imagePackage)
        setDetailInfraredUrl(infraredUrl ?? "");
        setDetailLightUrl(lightUrl ?? "");
      });
    }
  }, [
    detailCurrentPlant,
    detailCurrentTask,
    pointDefects,
    setDetailInfraredInfo,
    setDetailInfraredUrl,
    setDetailLightInfo,
    setDetailLightUrl,
  ]);

  useEffect(() => {
    detailLightUrl && setLightLoading(true);
  }, [detailLightUrl]);

  useEffect(() => {
    detailInfraredUrl && setInfraredLoading(true);
  }, [detailInfraredUrl]);

  const paintInfrared = () => {
    setInfraredLoading(false);
    detailInfraredInfo &&
      paintCanvas(
        detailInfraredInfo!.defects,
        reportCanvasRef.current!,
        infraredRef.current,
        thumbWidth,
        thumbHeight
      );
  };

  const paintLight = () => {
    setLightLoading(false);
    detailInfraredInfo &&
      paintCanvas(
        detailInfraredInfo!.defects,
        reportLightRef.current!,
        lightRef.current,
        thumbWidth,
        thumbHeight
      );
  };

  //暂时当前巡检点缺陷
  if (reportLoading) {
    return useObserver(() => (
      <div className="history-banner-wrapper">
        <div className="current-point-info">
          <Loading content="正在获取航点缺陷..." />
        </div>
      </div>
    ));
  }

  const LoadingWrapper = (
    <div className="image-loading-wrapper">
      <LoadingOutlined className="image-loading" />
    </div>
  );

  return useObserver(() => (
    <div className="detail-history-wrapper">
      <div className="current-point-info">
        <div className="current-point-name">
          <p>
            <span>航点:</span>
            <span>{`${detailCurrentPlant!.id}-${detailCurrentPoint!.task_id}-${
              detailCurrentPoint!.waypoint_id
            }-${detailCurrentPoint!.id}-${detailCurrentPoint!.index}`}</span>
          </p>
        </div>
        <div className="current-photo-wrapper">
          {/* <p>图片名称</p> */}
          <div className="current-photo-name">
            <span>{(detailInfraredInfo && detailInfraredInfo.name) || ""}</span>
            <span>{detailLightInfo && detailLightInfo.name}</span>
          </div>
          <div className="current-photo-image">
            {detailInfraredInfo && detailLightInfo && (
              <>
                <a
                  href={detailInfraredUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <div className="current-photo-infrared">
                    <div
                      className="report-image-wrapper"
                      style={{
                        height: thumbHeight,
                        width: thumbWidth,
                      }}
                    >
                      <canvas
                        width={thumbWidth}
                        height={thumbHeight}
                        ref={reportCanvasRef}
                        className="report-detail-canvas"
                      />
                      {infraredLoading && LoadingWrapper}
                      <img
                        src={detailInfraredUrl}
                        loading="lazy"
                        ref={infraredRef}
                        onLoad={paintInfrared}
                        alt="infrared"
                        style={{
                          marginLeft: 10,
                        }}
                      />
                    </div>
                  </div>
                </a>
                <a
                  href={detailLightUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <div className="current-photo-light">
                    <div
                      className="report-image-wrapper"
                      style={{
                        height: thumbHeight,
                        width: thumbWidth,
                      }}
                    >
                      <canvas
                        width={thumbWidth}
                        height={thumbHeight}
                        ref={reportLightRef}
                        className="report-detail-canvas"
                      />
                      {lightLoading && LoadingWrapper}
                      <img
                        src={detailLightUrl}
                        loading="lazy"
                        ref={lightRef}
                        onLoad={paintLight}
                        alt="infrared"
                      />
                    </div>
                  </div>
                </a>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  ));
});
