import { message, notification } from 'antd'
import { observer, useObserver } from 'mobx-react'
import React, { useEffect, useState } from 'react'
import io from 'socket.io-client'
import { ServiceApi } from '../../service/api'
import { appStore } from '../../store/appStore'
import { connectionStore } from '../../store/connectionStore'
import { mapStore } from '../../store/mapStore'
import { DroneInfo, SOCKET_EVENT } from '../../typings/interface'
import './ConnectionCenter.scss'
const { JOIN_ROOM, LIVE_STATUS } = SOCKET_EVENT
export const ConnectionCenter = observer(() => {
  const [lastDroneMessageTime, setLastDroneMessageTime] = useState<number | undefined>()
  const [socket, setSocket] = useState<SocketIOClient.Socket | undefined>()
  const { setDroneInfo, droneInfo } = connectionStore
  const { user } = appStore
  const { map } = mapStore

  // [socket] 注册socket监听

  // [socket] 启动
  useEffect(() => {
    if (localStorage.getItem('token')) {
      let socket: SocketIOClient.Socket | undefined
      const newUrl = ServiceApi.serviceUrl.replace('/api/', '')

      socket = io(`${newUrl}/message`, {
        autoConnect: true,
        transports: ['websocket', 'polling'],
        reconnection: true,
        reconnectionDelay: 1000,
        query: { token: localStorage.getItem('token') }
      })

      setSocket(socket)

      return () => {
        socket?.disconnect()
        socket?.close()
        setSocket(undefined)
      }
    }
  }, [setSocket])
  useEffect(() => {
    // 加入房间
    function joinRoom(socket: SocketIOClient.Socket, companyId: number) {
      socket.emit(JOIN_ROOM, { company_id: companyId, client: 1, user_phone: user?.phone, response_client_name: 'live_message' }, (data: any) => {
        console.log(data)
      })
    }

    if (socket && user?.company.id) {
      socket.on('connect', function () {
        console.log('监听')
        notification.success({
          placement: 'bottomRight',
          message: `通信服务连接成功`
        })

        joinRoom(socket!, +user?.company.id)
      })
      socket.on('init', (data: any) => {
        console.log(data) //这是后端推送过来的数据
      })

      socket.on('disconnect', function () {
        notification.warning({
          placement: 'bottomRight',
          message: `通信服务断开`
        })
        joinRoom(socket!, +user?.company.id)
      })

      socket.on('reconnect', (e: any) => {
        notification.success({
          placement: 'bottomRight',
          message: `通信服务重连成功`
        })
        joinRoom(socket!, +user?.company.id)
      })
      //接收无人机消息提示
      socket.on(LIVE_STATUS, (dataString: DroneInfo) => {
        console.log(dataString)
        const newDroneInfo: DroneInfo[] = JSON.parse(JSON.stringify(droneInfo))
        const index = newDroneInfo.findIndex(p => p.user_id === dataString.user_id && dataString.sender_state === 'down')
        if (index !== -1) {
          //无人机下线
          map!.viewer!.entities.values.filter((e: any) => e.user_id === dataString.user_id).forEach((l: any) => map!.viewer!.entities.remove(l))
          message.error(`用户${dataString.user_id}已下线`)
          droneInfo.splice(index, 1)
          // removeAllChild(dataString.user_id + '')
          var div: any = document.getElementById(dataString.user_id + '')
          if (div != null) div.parentNode.removeChild(div)
          setDroneInfo(droneInfo)
          return
        } else {
          const newDroneInfo: DroneInfo[] = JSON.parse(JSON.stringify(droneInfo))
          const index = newDroneInfo.findIndex(p => p.user_id === dataString.user_id)
          if (index !== -1) {
            newDroneInfo[index] = dataString
          } else {
            if (!dataString.longitude || !dataString.latitude) return
            message.success(`用户${dataString.user_id}上线`)
            newDroneInfo.unshift(dataString)
          }

          setDroneInfo(newDroneInfo)
        }

        setLastDroneMessageTime(+new Date())
      })
      socket.on('error', (err: any) => {
        notification.destroy()
        notification.warning({
          placement: 'bottomRight',
          message: `通信服务异常,即将重连`
        })
        console.error('[Socket Error]', err)
      })
    }
    return () => {
      socket?.off('connect')
      socket?.off('disconnect')
      socket?.off('reconnect')
      socket?.off('error')
      socket?.off(LIVE_STATUS)
    }
  }, [setSocket, socket, user, setDroneInfo, lastDroneMessageTime, droneInfo, map])

  function removeAllChild(useId: string) {
    var div: any = document.getElementById(useId)
    if (div != null) div.parentNode.removeChild(div)
    while (div!.hasChildNodes()) {
      //当div下还存在子节点时 循环继续
      div!.removeChild(div.firstChild)
    }
  }

  // useEffect(() => {
  //   let id: any
  //   if (lastDroneMessageTime) {
  //     id = setInterval(() => {
  //       const current = +new Date()
  //       const gap = current - lastDroneMessageTime
  //       notification.destroy()
  //       notification.warning({
  //         placement: 'bottomRight',
  //         message: `警告,无人机消息超时`,
  //         description: `已${gap / 1000}s未收到无人机消息`
  //       })
  //     }, 3000)
  //   }
  //   return () => id && clearInterval(id)
  // }, [lastDroneMessageTime])

  // // 关闭软件前的检查
  // useEffect(() => {
  //   // window.onbeforeunload =
  //   //   env.NODE_ENV === "production" && flyingTask
  //   //     ? function () {
  //   //         console.log("[当前飞行任务]", flyingTask);
  //   //         return Modal.warn({
  //   //           okText: "好的",
  //   //           content: `当前有任务正在飞行中:${flyingTask!.name}`,
  //   //           okButtonProps: { danger: true },
  //   //         });
  //   //       }
  //   //     : null;
  // }, [])

  return useObserver(() => <div className="connection-center-wrapper"></div>)
})
