import { getImageUrl } from "../../service/api";

export const getInfraredUrl = async (
  defect: any,
  currentPlantId: number,
  currentTaskId: number
) => {
  let infraredUrl: string | undefined = "";
  let lightUrl: string | undefined = "";

  let infraredImage: any | undefined = undefined;
  let lightImage: any | undefined = undefined;

  infraredImage = defect.images.find((image: any) => image.type === "2");
  lightImage = defect.images.find((image: any) => image.type === "1");

  if (infraredImage && lightImage && currentPlantId) {
    // infraredUrl = await getImageUrl(currentPlantId, currentTaskId, infraredImage.name)
    // lightUrl = await getImageUrl(currentPlantId, currentTaskId, lightImage.name)
    infraredUrl = infraredImage.oss_url ?? "";
    lightUrl = lightImage.oss_url ?? "";
  }

  return {
    infraredUrl,
    lightUrl,
    infraredImage,
    lightImage,
  };
};

export const getHistoryInfraredUrl = (
  detailItem: any,
  currentPlantId: number,
  currentTaskId: number
) => {
  let infraredUrl: string | undefined = "";
  let lightUrl: string | undefined = "";

  let infraredImage: any | undefined = {};
  let lightImage: any | undefined = {};
  //image_names: list, [可见光，红外]
  infraredImage.name = detailItem.image_names[1];
  lightImage.name = detailItem.image_names[0];

  infraredImage.defects = detailItem.defects.filter(
    (image: any) => image.image_type === "2"
  );
  lightImage.defects = detailItem.defects.filter(
    (image: any) => image.image_type === "1"
  );

  infraredUrl = getImageUrl(
    currentPlantId,
    currentTaskId,
    detailItem.image_names[1] + ""
  );
  lightUrl = getImageUrl(
    currentPlantId,
    currentTaskId,
    detailItem.image_names[0] + ""
  );

  return {
    infraredUrl,
    lightUrl,
    infraredImage,
    lightImage,
  };
};

export function paintCanvas(
  defects: any[],
  canvasRef: HTMLCanvasElement,
  imageRef: HTMLImageElement | null,
  canvasWidth: number,
  canvasHeight: number
) {
  if (!imageRef) {
    return console.error("PAINT CANVAS ERROR::传入的IMAGEREF为空");
  }
  let ctx = canvasRef.getContext("2d");

  if (!ctx) {
    return console.error("无法获得context,画布类型传入错误");
  }
  ctx.lineWidth = 2;
  ctx.clearRect(0, 0, canvasWidth, canvasHeight);
  if (defects && defects.length) {
    for (const item of defects) {
      const region = item.region;
      if (!region) {
        continue;
      }
      let { p1, p2, p3, p4 } = regionDestroyer(region);
      const picWidth = imageRef.naturalWidth;
      const picHeight = imageRef.naturalHeight;
      const ratio = { x: canvasWidth / picWidth, y: canvasHeight / picHeight };
      const color = `rgba(${item.defect_type.defect_color})`;
      if (color === null) {
        console.log("不显示该缺陷", item);
        return;
      }

      ctx.beginPath();
      ctx.strokeStyle = color;
      ctx.moveTo(p1.x * ratio.x, p1.y * ratio.y);
      ctx.lineTo(p2.x * ratio.x, p2.y * ratio.y);
      ctx.lineTo(p3.x * ratio.x, p3.y * ratio.y);
      ctx.lineTo(p4.x * ratio.x, p4.y * ratio.y);
      ctx.closePath();
      ctx.stroke();
    }
  }
}

export function regionDestroyer(region: string) {
  const arr = region.split(" ");
  const data = arr.map((item) => Number(item));
  const p1 = { x: data[0], y: data[1] };
  const p2 = { x: data[2], y: data[1] };
  const p3 = { x: data[2], y: data[3] };
  const p4 = { x: data[0], y: data[3] };
  const picWidth = data[4];
  const picHeight = data[5];

  return { p1, p2, p3, p4, picWidth, picHeight };
}
