import {
  ArrowsAltOutlined,
  QuestionCircleOutlined,
  ReloadOutlined,
  ShrinkOutlined,
  ZoomInOutlined,
  ZoomOutOutlined,
} from "@ant-design/icons";
import {
  Button,
  Dropdown,
  Input,
  Menu,
  message,
  Radio,
  Space,
  Tooltip,
} from "antd";
import axios from "axios";
import { Entity } from "cesium";
import { observer, useObserver } from "mobx-react-lite";
import React, { useEffect, useRef, useState } from "react";
import {
  api,
  downSoft,
  getDefectTypePoints,
  getReportCount,
  getTileMap,
} from "../../service/api";
import { ReportCount } from "../../store/reportStore";
import {
  DefectTypes,
  PacketType,
  PointsControlFilter,
} from "../../typings/interface";
import CesiumMap from "./CesiumMap";
import { gaoDeMapProvider, googleMapProvider } from "./config";
import "./DetailMap.scss";
import { formatDate, initDefaultMap } from "./methods";
import ShrinkAltSVG from "../../assets/svg/ShrinkAlt.svg";
import ArrowAltSVG from "../../assets/svg/ArrowAlt.svg";
import CloseSvg from "../../assets/svg/Close.svg";

export const DetailMap = observer(({ plantStore, detailStore }: any) => {
  const mapRef = useRef<CesiumMap>(null);
  const { Search } = Input;
  const [currentPlantTiff, setCurrentPlantTiff] = useState<any>(null);

  const {
    setDetailMap,
    detailGoogleMapAvailable,
    detailCurrentPlant,
    setDetailCurrentPoint,
    detailCurrentTask,
    detailPoints,
    detailDefects,
    detailCurrentPoint,
    detailFilterPointsStatus,
    setDetailFilterPointsStatus,
    detailDefectTypes,
    detailToken,
    setDetailReportCount,
    detailReportCount,
    setDetailPoints,
    detailFilterPointsName,
    setDetailFilterPointsName,
    detailMiniMap,
    detailInfraredUrl,
    detailLightUrl,
    detailInfraredInfo,
    detailLightInfo,
    setDetailInfraredInfo,
    setDetailLightInfo,
    setDetailInfraredUrl,
    setDetailLightUrl,
    setDetailMiniMap,
  } = detailStore;

  useEffect(() => {
    const getCurrentTaskReportCount = async () => {
      const reportCount: ReportCount = await getReportCount(
        detailCurrentTask!.id
      );
      setDetailReportCount(reportCount);
    };
    if (detailCurrentTask) {
      getCurrentTaskReportCount();
    }
  }, [detailCurrentTask, setDetailReportCount]);

  useEffect(() => {
    if (mapRef.current) {
      setDetailMap(mapRef.current);
      initDefaultMap(mapRef.current);
      mapRef.current.addNetworkMapLayer(gaoDeMapProvider);
    }
    return () => {
      setDetailMap(null); // 主动解除地图
    };
  }, [setDetailMap]);

  useEffect(() => {
    if (mapRef.current && detailGoogleMapAvailable !== null) {
      detailGoogleMapAvailable
        ? mapRef.current.addNetworkMapLayer(googleMapProvider)
        : mapRef.current.addNetworkMapLayer(gaoDeMapProvider);
    }
  }, [detailGoogleMapAvailable]);

  useEffect(() => {
    if (
      !mapRef.current ||
      !detailCurrentPlant ||
      !detailCurrentPlant.longitude ||
      !detailCurrentPlant.latitude
    ) {
      return;
    }
    mapRef.current.flyLongitudeTo(
      +detailCurrentPlant.longitude,
      +detailCurrentPlant.latitude
    );
  }, [detailCurrentPlant, mapRef]);

  // 加载电厂正射
  useEffect(() => {
    if (!mapRef.current || !detailCurrentPlant) {
      return;
    }
    mapRef.current.removeAllEntity(); // 电站变化时清理所有点
    console.log("map:%c电站加载: ", "color:blue", detailCurrentPlant.id);
    const tiffUrl = getTileMap(detailCurrentPlant!.id);
    console.log(tiffUrl);
    const level = 22;
    mapRef.current!.loadTiff(
      tiffUrl,
      [+detailCurrentPlant.longitude, +detailCurrentPlant.latitude],
      level,
      setCurrentPlantTiff
    );
  }, [detailCurrentPlant, mapRef]);
  const mapTips = (
    <div>
      <p>1.鼠标滚轮进行缩放</p>
      <p>2.点击地球拖拽移动视角</p>
      <p>3.按住鼠标中键旋转视角</p>
    </div>
  );
  // 组件卸载
  useEffect(() => {
    const map = mapRef.current;
    return () => {
      map?.removeAllEntity();
      map?.viewer!.destroy();
      setDetailMap(null);
      console.log("%c[Map组件卸载]", "color:orangered");
    };
  }, [setDetailMap]);

  // 加载航点
  useEffect(() => {
    const getFilterPoints = async () => {
      if (!mapRef.current) {
        return;
      }
      message.destroy();
      message.loading({
        content: "航点渲染中...",
        duration: 0,
        key: detailFilterPointsName,
      });

      if (detailFilterPointsName !== "") {
        //图文搜索
        const point = await getDefectTypePoints(
          detailCurrentTask!.id,
          null,
          detailFilterPointsName
        );
        await mapRef.current.paintPoints(point, mapRef.current);
        setDetailPoints(point);
      } else {
        console.log(detailFilterPointsStatus);
        const point = await getDefectTypePoints(
          detailCurrentTask!.id,
          detailFilterPointsStatus
        );
        await mapRef.current.paintPoints(point, mapRef.current);
        setDetailPoints(point);
      }

      message.destroy();
      message.success({
        content: "航点渲染完成...",
        key: detailFilterPointsStatus,
      });
    };

    if (detailCurrentTask) {
      getFilterPoints();
    }
  }, [
    detailCurrentTask,
    detailFilterPointsName,
    detailFilterPointsStatus,
    setDetailPoints,
  ]);

  // 渲染region
  useEffect(() => {
    if (!mapRef.current || !detailCurrentPoint) {
      return;
    }
    if (!detailCurrentPoint) return;
    const flag = mapRef.current!.viewer!.entities.values.find(
      (item: any) => item.id === detailCurrentPoint!.id
    );

    if (flag) {
      console.log("渲染region");
      mapRef
        .current!.viewer!.entities.values.filter(
          (e: any) => e.polygon && e.description.getValue() === "region"
        )
        .forEach((l) => mapRef.current!.viewer!.entities.remove(l));

      mapRef.current.cesiumPointPolygon(detailCurrentPoint);
    }
  }, [
    detailCurrentPoint,
    detailDefects,
    detailFilterPointsStatus,
    detailPoints,
  ]);

  useEffect(() => {
    if (currentPlantTiff && !detailCurrentPlant) {
      console.log("清理电站底图");
      mapRef.current!.viewer!.imageryLayers.remove(currentPlantTiff);
    }
  }, [detailCurrentPlant, currentPlantTiff]);

  const handleClick = (entity: Entity) => {
    if (entity.name === "point") {
      const detailCurrentPoint: any = detailPoints.find(
        (point: any) => point.id === entity.id
      );
      if (detailCurrentPoint) {
        setDetailCurrentPoint(detailCurrentPoint);
      }
    }
  };

  const onChange = (value: any) => {
    setDetailFilterPointsStatus(value);
    setDetailFilterPointsName("");
  };
  const searchChange = (e: any) => {
    if (e.target.value === "") {
      setDetailFilterPointsStatus(8); //为空时,默认搜索全部
      setDetailFilterPointsName("");
    }
    // setDetailFilterPointsName(e.target.value)
  };
  //搜索
  const onSearch = (value: any) => {
    setDetailFilterPointsName("");
    setDetailFilterPointsStatus(null);
  };
  useEffect(() => {
    if (detailFilterPointsStatus) {
      setDetailCurrentPoint(null);
    }
  }, [detailFilterPointsStatus, setDetailCurrentPoint]);
  console.log(detailDefectTypes);
  const filterMenu = (
    <Menu selectedKeys={[detailFilterPointsStatus + ""]}>
      {detailDefectTypes.map((item: DefectTypes) => (
        <Menu.Item key={item.id} onClick={() => onChange(item.id)}>
          {item.defect_name}
        </Menu.Item>
      ))}
    </Menu>
  );
  const downLoadReport = async () => {
    let requestData = {
      project_id: detailCurrentPlant.id,
      packet_type: PacketType.report,
      task_id: detailCurrentTask.id,
    };

    let config: any = {
      method: "GET",
      token: detailToken,
      url: api.getManagementPackets,
      params: requestData,
      headers: { "X-TOKEN": `${detailToken}` },
    };
    axios(config).then(async (res) => {
      console.log(res);
      if (res.data === null) {
        message.error("当前任务暂无报告");
      } else {
        message.loading({
          content: "报告下载中",
          duration: 0,
          key: detailCurrentTask.id,
        });
        let DownloadLink: any = document.createElement("a");
        DownloadLink.style = "display: none"; // 创建一个隐藏的a标签
        DownloadLink.download = res.data;
        DownloadLink.href = res.data;
        document.body.appendChild(DownloadLink);
        DownloadLink.click(); // 触发a标签的click事件
        document.body.removeChild(DownloadLink);
        message.success({
          content: "报告下载成功",
          key: detailCurrentTask.id,
        });
        // await downSoft(detailCurrentPlant.id, detailCurrentTask.id)
        //   .then((downloadSkywayUrl: any) => {
        //     console.log(downloadSkywayUrl);
        //     let DownloadLink: any = document.createElement("a");
        //     DownloadLink.style = "display: none"; // 创建一个隐藏的a标签
        //     DownloadLink.download = downloadSkywayUrl;
        //     DownloadLink.href = downloadSkywayUrl;
        //     document.body.appendChild(DownloadLink);
        //     DownloadLink.click(); // 触发a标签的click事件
        //     document.body.removeChild(DownloadLink);
        //     message.success({
        //       content: "报告下载成功",
        //       key: detailCurrentTask.id,
        //     });
        //   })
        //   .catch((error) => {
        //     console.log(error);
        //     message.error({
        //       content: "报告下载失败",
        //       key: detailCurrentTask.id,
        //     });
        //   });
      }
    });
  };
  const handleMinClick = () => {
    setDetailMiniMap(!detailMiniMap);
  };

  console.log(detailMiniMap);
  return useObserver(() => (
    <div className={`detail-map-wrapper ${detailMiniMap ? "mini" : ""}`}>
      {/*  {detailPoints.length >= 1 && detailCurrentPoint && ( */}
      {/*   <Tooltip placement="bottomRight" title={detailMiniMap ? '返回地图模式' : '进入大图模式'}> */}
      {/*     <button onClick={handleMinClick} className="map-expand-button"> */}
      {/*       {detailMiniMap ? <ShrinkOutlined /> : <ArrowsAltOutlined />} */}
      {/*     </button> */}
      {/*   </Tooltip> */}
      {/* )} */}
      {detailPoints.length >= 1 && detailCurrentPoint && (
        <Tooltip
          placement="bottomRight"
          title={detailMiniMap ? "返回地图模式" : "进入大图模式"}
        >
          <Button
            onClick={handleMinClick}
            className="map-expand-button"
            style={{ right: 40 }}
          >
            {detailMiniMap ? (
              <img src={ShrinkAltSVG} alt="shrink" width={20} height={20} />
            ) : (
              <img src={ArrowAltSVG} alt="arrow" width={20} height={20} />
            )}
          </Button>
        </Tooltip>
      )}
      {detailPoints.length >= 1 && detailCurrentPoint && (
        <Button
          onClick={() => setDetailCurrentPoint(null)}
          className="map-expand-button"
          style={{
            right: 10,
          }}
        >
          <img src={CloseSvg} alt="close" width={20} height={20} />
        </Button>
      )}

      {detailCurrentPlant && detailCurrentTask && !detailMiniMap && (
        <div className="plant-info-header">
          <div className="plant-info">
            <h2>{detailCurrentPlant!.name}</h2>
            <p>
              <span>经度</span>
              <span>{detailCurrentPlant!.longitude}</span>
            </p>
            <p>
              <span>纬度</span>
              <span>{detailCurrentPlant!.latitude}</span>
            </p>
            {detailCurrentTask && (
              <p>
                <span>缺陷航点/全部航点数</span>
                <span>
                  {detailReportCount.has_defect_num}/{" "}
                  {detailReportCount.has_defect_num +
                    detailReportCount.no_defect_num}
                </span>
              </p>
            )}
          </div>
          <div className="task-info">
            <p>
              <span>{detailCurrentTask?.name}</span>
              <span> {formatDate(detailCurrentTask?.create_time)}</span>
            </p>
            <p>
              <Button onClick={downLoadReport}>下载报告</Button>
            </p>
          </div>
        </div>
      )}

      {detailCurrentTask && !detailMiniMap && (
        <div className="map-control-filter">
          <Radio.Group value={detailFilterPointsStatus}>
            <Radio.Button
              value={PointsControlFilter.AllPoints}
              onClick={() => onChange(PointsControlFilter.AllPoints)}
            >
              全部
            </Radio.Button>
            <Radio.Button
              value={PointsControlFilter.FinesDefect}
              onClick={() => onChange(PointsControlFilter.FinesDefect)}
            >
              无缺陷
            </Radio.Button>
            <Radio.Button
              value={PointsControlFilter.hasDefect}
              onClick={() => onChange(PointsControlFilter.hasDefect)}
            >
              有缺陷
            </Radio.Button>
            <Dropdown overlay={filterMenu} placement="bottomLeft">
              <Button type="ghost">类型筛选</Button>
            </Dropdown>
          </Radio.Group>
          <Space direction="vertical" className="map-control-search">
            <Search
              placeholder="输入照片名以搜索"
              onChange={searchChange}
              onSearch={onSearch}
            />
          </Space>
        </div>
      )}
      {!detailMiniMap && (
        <div className="controls">
          <Tooltip placement="left" overlay={mapTips}>
            <QuestionCircleOutlined />
          </Tooltip>

          <Tooltip placement="left" title="回到电站">
            <ReloadOutlined
              onClick={() =>
                mapRef.current?.flyToCenter([
                  +detailCurrentPlant.longitude,
                  +detailCurrentPlant.latitude,
                ])
              }
            />
          </Tooltip>
          <Tooltip placement="left" title="放大">
            <ZoomInOutlined onClick={() => mapRef.current?.zoomIn()} />
          </Tooltip>
          <Tooltip placement="left" title="缩小">
            <ZoomOutOutlined onClick={() => mapRef.current?.zoomOut()} />
          </Tooltip>
        </div>
      )}

      <CesiumMap ref={mapRef} clickCallback={handleClick} />
    </div>
  ));
});
