import Icon, {
	ArrowsAltOutlined,
	LoadingOutlined,
	ShrinkOutlined,
} from "@ant-design/icons";
import { Button, Divider, message, Tooltip } from "antd";
import { observer, useObserver } from "mobx-react-lite";
import React, { useEffect, useRef, useState } from "react";
import { getPointDefects, getTaskHistoryDefects } from "../../service/api";
import { mapStore } from "../../store/mapStore";
import { plantStore } from "../../store/plantStore";
import { reportStore } from "../../store/reportStore";
import { taskStore } from "../../store/taskStore";
import HistoryPoint from "../HistoryPoint";
import Loading from "../Loading";
import "./HistoryBanner.scss";
import { getInfraredUrl, paintCanvas } from "./methods";
import ShrinkAltSVG from "../../assets/svg/ShrinkAlt.svg";
import CloseSVG from "../../assets/svg/Close.svg";
import ArrowAltSVG from "../../assets/svg/ArrowAlt.svg";

const thumbWidth = 192;
const thumbHeight = 154;

export const HistoryBanner = observer(() => {
	const { currentPoint, currentTask, tasks, setCurrentPoint } = taskStore;
	const { currentPlant } = plantStore;
	const { setMap, googleMapAvailable, map, miniMap, setMiniMap } = mapStore;

	const {
		infraredUrl,
		setInfraredUrl,
		lightUrl,
		setLightUrl,
		infraredInfo,
		setInfraredInfo,
		lightInfo,
		setLightInfo,
	} = mapStore;

	const infraredRef = useRef<HTMLImageElement>(null);
	const lightRef = useRef<HTMLImageElement>(null);

	const reportCanvasRef = useRef<HTMLCanvasElement>(null);
	const reportLightRef = useRef<HTMLCanvasElement>(null);
	const {
		setPointDefects,
		pointDefects,
		setHistoryPointDefects,
		historyPointDefects,
	} = reportStore;
	const [reportLoading, setReportLoading] = useState(false);

	// const [infraredUrl, setInfraredUrl] = useState('')
	// const [lightUrl, setLightUrl] = useState('')
	const [infraredLoading, setInfraredLoading] = useState(false);
	const [lightLoading, setLightLoading] = useState(false);
	// const [infraredInfo, setInfraredInfo] = useState<any>(null)
	// const [lightInfo, setLightInfo] = useState<any>(null)

	const [taskHistoryLoading, setTaskHistoryLoading] = useState(false);

	useEffect(() => {
		console.log(11);
		const getCurrentPointDefects = async () => {
			const pointDefects = await getPointDefects(
				currentTask!.id,
				currentPoint!.id
			);
			setPointDefects(pointDefects);
			setReportLoading(false);
		};
		if (currentPoint && currentTask) {
			setReportLoading(true);
			getCurrentPointDefects();
		}
	}, [currentPoint, currentTask, setPointDefects]);

	useEffect(() => {
		if (pointDefects) {
			getInfraredUrl(pointDefects, currentPlant!.id, currentTask!.id).then(
				(res) => {
					const { infraredImage, lightImage, infraredUrl, lightUrl } = res;
					setInfraredInfo(infraredImage);
					setLightInfo(lightImage);
					const imagePackage = {
						infraredInfo: infraredImage,
						lightInfo: lightImage,
					};
					console.log({ imagePackage });
					console.log(infraredImage, lightImage);
					// setImageInfos(imagePackage)
					setInfraredUrl(infraredUrl ?? "");
					setLightUrl(lightUrl ?? "");
				}
			);
		}
	}, [
		currentPlant,
		currentTask,
		pointDefects,
		setInfraredInfo,
		setInfraredUrl,
		setLightInfo,
		setLightUrl,
	]);

	useEffect(() => {
		lightUrl && setLightLoading(true);
	}, [lightUrl]);

	useEffect(() => {
		infraredUrl && setInfraredLoading(true);
	}, [infraredUrl]);

	const paintInfrared = () => {
		setInfraredLoading(false);
		infraredInfo &&
			paintCanvas(
				infraredInfo!.defects,
				reportCanvasRef.current!,
				infraredRef.current,
				thumbWidth,
				thumbHeight
			);
	};

	const paintLight = () => {
		setLightLoading(false);
		infraredInfo &&
			paintCanvas(
				infraredInfo!.defects,
				reportLightRef.current!,
				lightRef.current,
				thumbWidth,
				thumbHeight
			);
	};

	//暂时当前巡检点缺陷
	if (reportLoading) {
		return useObserver(() => (
			<div className="history-banner-wrapper">
				<div className="current-point-info">
					<Loading content="正在获取航点缺陷..." />
				</div>
			</div>
		));
	}

	const LoadingWrapper = (
		<div className="image-loading-wrapper">
			<LoadingOutlined className="image-loading" />
		</div>
	);
	//获取历史任务巡检点缺陷
	const getTaskHistory = async () => {
		setHistoryPointDefects(null);
		setTaskHistoryLoading(true);
		const filterTask = tasks.filter((task) => task.id !== currentTask!.id);
		if (!filterTask.length) {
			setTaskHistoryLoading(false);
			message.error("当前航点没有历史记录");
			return;
		}
		await getTaskHistoryDefects(filterTask, currentPoint!.waypoint_id)
			.then((res) => {
				setHistoryPointDefects(res);
				setTaskHistoryLoading(false);
			})
			.catch((error) => {
				console.log(error, "失败");
				setTaskHistoryLoading(false);
			});
	};
	const handleMinClick = () => {
		setMiniMap(!miniMap);
	};

	const handleClose = () => {
		setCurrentPoint(null);
	};

	return useObserver(() => (
		<div className="history-banner-wrapper">
			{currentPoint && (
				<Tooltip
					placement="bottomRight"
					title={miniMap ? "返回地图模式" : "进入大图模式"}
				>
					<Button onClick={handleMinClick} className="shrink-btn">
						{miniMap ? (
							<img src={ShrinkAltSVG} alt="shrink" width={20} height={20} />
						) : (
							<img src={ArrowAltSVG} alt="arrow" width={20} height={20} />
						)}
					</Button>
				</Tooltip>
			)}
			{/* {currentPoint && ( */}
			{/* 	<Tooltip */}
			{/* 		placement="bottomRight" */}
			{/* 		title={miniMap ? "返回地图模式" : "进入大图模式"} */}
			{/* 	> */}
			{/* 		<button onClick={handleMinClick} className="map-expand-button"> */}
			{/* 			{miniMap ? <ShrinkOutlined /> : <ArrowsAltOutlined />} */}
			{/* 		</button> */}
			{/* 	</Tooltip> */}
			{/* )} */}

			<div className="current-point-info">
				<div className="banner-header">
					<div className="current-point-title">
						<p className="header-title">任务名称:{currentTask?.name}</p>
						<p className="header-subtitle">
							航点:
							{`${currentPlant?.id}-${currentPoint?.task_id}-${currentPoint?.waypoint_id}-${currentPoint?.id}-${currentPoint?.index}`}
						</p>
					</div>

					<div className="banner-button">
						{/* {currentPoint && ( */}
						{/* 	<Tooltip */}
						{/* 		placement="bottomRight" */}
						{/* 		title={miniMap ? "返回地图模式" : "进入大图模式"} */}
						{/* 	> */}
						{/* 		<Button onClick={handleMinClick} className="map-expand-button"> */}
						{/* 			{miniMap ? ( */}
						{/* 				<img */}
						{/* 					src={ShrinkAltSVG} */}
						{/* 					alt="shrink" */}
						{/* 					width={20} */}
						{/* 					height={20} */}
						{/* 				/> */}
						{/* 			) : ( */}
						{/* 				<img src={ArrowAltSVG} alt="arrow" width={20} height={20} /> */}
						{/* 			)} */}
						{/* 		</Button> */}
						{/* 	</Tooltip> */}
						{/* )} */}
						<Tooltip placement="bottomRight" title={"关闭"}>
							<Button onClick={handleClose} className="map-expand-button">
								<img src={CloseSVG} alt="close" width={20} height={20} />
							</Button>
						</Tooltip>
					</div>
				</div>
				<Divider style={{ margin: 0 }} />

				<div className="current-photo-wrapper">
					{/* <p>图片名称</p> */}

					<div className="current-photo-image">
						{infraredInfo && lightInfo && (
							<>
								<a href={infraredUrl} target="_blank" rel="noopener noreferrer">
									<div className="current-photo-infrared">
										<div
											className="report-image-wrapper"
											style={{
												height: thumbHeight,
												width: thumbWidth,
											}}
										>
											<canvas
												width={thumbWidth}
												height={thumbHeight}
												ref={reportCanvasRef}
												className="report-detail-canvas"
											/>
											{infraredLoading && LoadingWrapper}
											<img
												src={infraredUrl}
												loading="lazy"
												ref={infraredRef}
												onLoad={paintInfrared}
												alt="infrared"
											/>
										</div>
									</div>
								</a>
								<a href={lightUrl} target="_blank" rel="noopener noreferrer">
									<div className="current-photo-light">
										<div
											className="report-image-wrapper"
											style={{
												height: thumbHeight,
												width: thumbWidth,
											}}
										>
											<canvas
												width={thumbWidth}
												height={thumbHeight}
												ref={reportLightRef}
												className="report-detail-canvas"
											/>
											{lightLoading && LoadingWrapper}
											<img
												src={lightUrl}
												loading="lazy"
												ref={lightRef}
												onLoad={paintLight}
												alt="infrared"
											/>
										</div>
									</div>
								</a>
							</>
						)}
					</div>
					<div className="current-photo-name">
						<span>{infraredInfo?.name || ""}</span>
						<span>{lightInfo?.name}</span>
					</div>
					<div className="current-photo-history">
						<Button onClick={() => getTaskHistory()}>查看历史记录</Button>
					</div>
				</div>
			</div>
			{taskHistoryLoading && (
				<div className="detail-history-wrapper">
					{<Loading content="正在获取历史航点缺陷..." />}
				</div>
			)}

			{historyPointDefects && historyPointDefects.length >= 1 && (
				<div className="detail-history-wrapper">
					{historyPointDefects.map((item: any) => (
						<HistoryPoint detailItem={item} key={item.id} />
					))}
				</div>
			)}
			{/* <HistoryPoint taskHistoryLoading={taskHistoryLoading} /> */}
		</div>
	));
});
