import "antd/dist/antd.less";
import "mobx-react-lite/batchingForReactDom";
import React from "react";
import ReactDOM from "react-dom";
import "../node_modules/cesium/Source/Widgets/widgets.css";
import App from "./App";
import "./assets/css/reset.css";
import "./assets/css/font.css";

// ReactDOM.render(
//   <React.StrictMode>
//     <App />
//   </React.StrictMode>,
//   document.getElementById('root')
// )
ReactDOM.render(<App />, document.getElementById("root"));
