import { message } from 'antd'
import axios from 'axios'
import { ServiceApi, signOut } from './api'

axios.defaults.timeout = 30000

axios.defaults.baseURL = ServiceApi.serviceUrl

axios.interceptors.request.use(
  (config: any) => {
    if (localStorage.token) {
      config.headers.common['X-TOKEN'] = `${localStorage.getItem('token')}`
    }
    return config
  },
  error => {
    message.warn('请求超时,稍后再试')
    return Promise.reject(error)
  }
)

axios.interceptors.response.use(
  response => {
    return response.data
  },
  error => {
    if (error?.response?.status === 401) {
      console.log('%cRemoving all personal data', 'color:red')
      message.error('身份认证过期,请重新登录')
      localStorage.removeItem('token')
      localStorage.removeItem('user')
      signOut()
    }
    if (error.code === 'ECONNABORTED') {
      message.error('请求超时，稍后再试')
    }
    return Promise.reject(error)
  }
)
