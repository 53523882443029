import flvjs from 'flv.js'
import React, { useEffect, useRef, useState } from 'react'
import LiveReload from '../LiveReload'
import { FlvStreamPayload } from '../LiveView/LiveView'
import './FlvLive.scss'

interface Props {
  liveOptions: FlvStreamPayload
}

export const FlvLive = ({ liveOptions }: Props) => {
  const { url, name } = liveOptions
  const videoRef = useRef<HTMLVideoElement>(null)
  const [flvPlayer, setFlvPlayer] = useState<any>(null)

  useEffect(() => {
    let player: any
    if (flvjs.isSupported() && videoRef.current && url) {
      try {
        player = flvjs.createPlayer({ type: 'flv', url, hasAudio: false, isLive: true, duration: 0 }, { enableStashBuffer: false, fixAudioTimestampGap: false })
        player.attachMediaElement(videoRef.current)
        let playPromise = videoRef.current.play()
        player.load()

        if (playPromise !== undefined) {
          playPromise
            .then(_ => {
              player.play()
            })
            .catch(error => {
              // Auto-play was prevented
              // Show paused UI.
            })
        }

        player.on(flvjs.Events.MEDIA_INFO, (info: any) => {
          console.log('[Media Info]', { info })
        })

        setFlvPlayer(player)
        console.log('[FLV Live Attached]', { player })
      } catch (e) {
        console.error('[FLV Live Failed]', e)
      }
    }
    return () => player && player.destroy()
  }, [url])

  // 追帧
  useEffect(() => {
    const video = videoRef.current!
    let id: any
    function jump() {
      if (video.buffered.length > 0) {
        const diff = video.buffered.end(0) - video.currentTime
        if (diff > 6) {
          console.log('[Frame Jumping]', { name, diff })
          video.currentTime = video.buffered.end(0) - 0.3
        }
      }
    }
    if (video && name) {
      id = setInterval(jump, 10000)
    }

    return () => {
      clearInterval(id)
    }
  }, [name])

  return (
    <div className="flv-live-wrapper">
      <video className="flv-live" controls={true} muted ref={videoRef} />
      <LiveReload flvPlayer={flvPlayer} />
    </div>
  )
}
