import Axios from 'axios'
import { observer, useObserver } from 'mobx-react'
import React, { useEffect } from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import { ROUTERS } from '../../assets/js/constants'
import { getCurrentUserPermission } from '../../assets/js/methods'
import Header from '../../components/Header'
import NotFound from '../../components/NotFound'
import { Guard } from '../../router/Guard'
import { syncBasic } from '../../service/api'
import { appStore } from '../../store/appStore'
import { mapStore } from '../../store/mapStore'
import { RolesType } from '../../typings/interface'
import ConnectionCenter from '../ConnectionCenter'
import Plants from '../plants'
import './Home.scss'
export const Home = observer(() => {
  const { setUser, user } = appStore

  const { setGoogleMapAvailable } = mapStore
  //更新人员信息
  useEffect(() => {
    const getCurrentUser = async () => {
      const userInfo = await syncBasic()
      console.log(userInfo, '本地人员信息')
      setUser(userInfo)
      localStorage.setItem('user', JSON.stringify(userInfo))
    }
    getCurrentUser()
    //获取user信息
  }, [setUser])

  useEffect(() => {
    const axios = Axios.create()
    axios
      .get('http://mt3.google.cn/maps/vt?lyrs=s&x=5&y=0&z=3', { timeout: 1000 })
      .then(res => {
        console.log('%c网络环境:GoogleMap:ALIVE', 'color:green')
        setGoogleMapAvailable(false)
      })
      .catch(e => {
        console.log('%c网络环境:GoogleMap:DEAD', 'color:red')
        setGoogleMapAvailable(false)
      })
  }, [setGoogleMapAvailable])

  return useObserver(() => (
    <div className="home-wrapper">
      <Guard />
      {user ? (
        <>
          {getCurrentUserPermission(RolesType.DataCenterLiveBroadcast, user!.permissions) && <ConnectionCenter />}
          <Header />
          <Switch>
            <Route path={ROUTERS.PLANTS} component={Plants} />
            <Redirect from={ROUTERS.HOME} exact to={ROUTERS.PLANTS} />
            <Route path="*">
              <NotFound />
            </Route>
          </Switch>
        </>
      ) : (
        <></>
      )}
    </div>
  ))
})
