import { LoadingOutlined } from '@ant-design/icons'
import React from 'react'
import './Loading.scss'

export const Loading = ({ content }: any) => {
  return (
    <div className="loading-wrapper">
      <LoadingOutlined />
      <br />
      <div className="content">{content}</div>
    </div>
  )
}
