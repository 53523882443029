import { observer, useObserver } from 'mobx-react'
import React from 'react'
import './App.scss'
import Router from './router'
import './service/axios'

const App = observer(() => {
  return useObserver(() => (
    <div className="App">
      <Router />
    </div>
  ))
})
export default App
