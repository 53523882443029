import { Base64 } from "js-base64";
import { observer, useObserver } from "mobx-react-lite";
import React from "react";
import { useHistory } from "react-router-dom";
import DetailImageViewer from "../../components/DetailImageViewer";
import DetailHistory from "../../components/DetailHistory";
import DetailMap from "../../components/DetailMap";
import Loading from "../../components/Loading";
import useGetDetailPoints from "../../hooks/useGetDetailPoints";
import useGetTaskDetail from "../../hooks/useGetTaskDetail";
import { detailStore } from "../../store/detailStore";
import { plantStore } from "../../store/plantStore";
import "./TaskDetail.scss";
export const TaskDetail = observer(() => {
  const history = useHistory();
  // const [token, setToken] = useState("")
  console.log(history);
  const paramsString = history.location.search.substring(1);
  const searchParams = new URLSearchParams(paramsString);
  const token = Base64.decode(searchParams.get("token") || "");
  const plantId = searchParams.get("plantid");
  const taskId = searchParams.get("taskid");
  const { detailCurrentPoint, setDetailToken, detailMiniMap } = detailStore;
  const getTaskLoading = useGetTaskDetail({ token, plantId, taskId });
  const getTaskPointLoading = useGetDetailPoints({ token, plantId, taskId });

  setDetailToken(token);
  // if(taskId)
  // {
  //   setDetailTask(taskId)
  // }
  // console.log(token, plantId, taskId)
  // setDetailToken(token)

  // setDetailPlant(plantId)

  //暂时当前巡检点缺陷
  if (getTaskPointLoading) {
    return useObserver(() => (
      <div className="history-banner-wrapper">
        <div className="current-point-info">
          <Loading content="正在获取航点缺陷..." />
        </div>
      </div>
    ));
  }

  return useObserver(() => (
    <div className="task-detail-wrapper">
      {detailMiniMap && <DetailImageViewer />}
      {detailCurrentPoint && <DetailHistory />}
      <DetailMap plantStore={plantStore} detailStore={detailStore} />
    </div>
  ));
});
