import { ProviderViewModel, ShadowMode, TileMapServiceImageryProvider, UrlTemplateImageryProvider, WebMapTileServiceImageryProvider, WebMercatorTilingScheme } from 'cesium'
//! 无网络/低带宽/默认
export const privateMapProvider = new TileMapServiceImageryProvider({
  url: '/Cesium/Assets/Textures/NaturalEarthII',
  minimumLevel: 1,
  maximumLevel: 3,
  fileExtension: 'jpg'
})

//!bing
// export const bingMapProvider = new BingMapsImageryProvider({
//   url: "https://dev.virtualearth.net",
//   key: "AibZIO16ewIVs9Laf-ovNHYfTwg6H2JsmAzEjRJ9qNpkAuGw9_VJrHG95GHPMyop"
// });

//!gao de
export const gaoDeMapProvider = new UrlTemplateImageryProvider({
  url: 'https://webst01.is.autonavi.com/appmaptile?style=6&x={x}&y={y}&z={z}',
  tilingScheme: new WebMercatorTilingScheme(),
  minimumLevel: 1,
  maximumLevel: 15
})

//! mt3.google.cn
export const googleMapProvider = new UrlTemplateImageryProvider({
  url: 'http://mt3.google.cn/maps/vt?lyrs=s&x={x}&y={y}&z={z}', //影像图 (中国范围无偏移)
  tilingScheme: new WebMercatorTilingScheme(),
  minimumLevel: 1,
  maximumLevel: 21,
  credit: 'Google Earth'
})

export const gaoDeMapsssProvider: any = new WebMapTileServiceImageryProvider({
  url: 'http://t0.tianditu.com/vec_w/wmts?service=wmts&request=GetTile&version=1.0.0&LAYER=vec&tileMatrixSet=w&TileMatrix={TileMatrix}&TileRow={TileRow}&TileCol={TileCol}&style=default&format=tiles&tk=4fe2e36ffb3102d55e58ac2146ac3d9e',
  layer: 'tdtImgAnnoLayer',
  style: 'default',
  format: 'image/png',
  tileMatrixSetID: 'GoogleMapsCompatible',
  show: false
} as any)

//! baidu.cn
export const baiduMapProvider = new UrlTemplateImageryProvider({
  //影像图 (中国范围无偏移)
  url: 'http://webst02.is.autonavi.com/appmaptile?x={x}&y={y}&z={z}&lang=zh_cn&size=1&scale=1&style=8',
  // layer: "tdtAnnoLayer",
  // style: "default",
  // format: "image/jpeg",
  // tileMatrixSetID: "GoogleMapsCompatible",

  tilingScheme: new WebMercatorTilingScheme(),
  minimumLevel: 1,
  maximumLevel: 21
})
gaoDeMapsssProvider.errorEvent.addEventListener((e: any) => {
  e.error.code !== 11 && e.error.statusCode !== 404 && console.log(e)
})

baiduMapProvider.errorEvent.addEventListener(e => {
  e.error.code !== 11 && e.error.statusCode !== 404 && console.log(e)
})
googleMapProvider.errorEvent.addEventListener(e => {
  e.error.code !== 11 && e.error.statusCode !== 404 && console.log(e)
})

gaoDeMapProvider.errorEvent.addEventListener(e => {
  e.error.code !== 11 && e.error.statusCode !== 404 && console.log(e)
})

privateMapProvider.errorEvent.addEventListener(e => {
  e.error.code !== 11 && e.error.statusCode !== 404 && console.log(e)
})

const CESIUM_CONFIG = {
  ION: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJqdGkiOiJhMGRlYTM0ZS0zYjQzLTQ0N2EtYTk4ZS0zNmIwMmU3MDRkNTIiLCJpZCI6MTkzMSwiaWF0IjoxNTMwNzU5NTg3fQ.nt8CVoWjIXTeDM9T6qPs-dM7tb7IWnNc56mzAqhcBBY',
  MAPOPTIONS: {
    imageryProviderViewModels: [
      new ProviderViewModel({
        name: 'default tiles',
        iconUrl: '',
        tooltip: 'default tiles',
        creationFunction: function () {
          return privateMapProvider
        }
      })
    ], //设置影像图列表
    terrainProviderViewModels: [], //设置地形图列表
    baseLayerPicker: true, // 默认选择googleEarth图层
    fullscreenButton: false,
    vrButton: false,
    homeButton: false,
    infoBox: false,
    sceneModePicker: false,
    selectionIndicator: false,
    timeline: false,
    navigationHelpButton: false,
    animation: false,
    shouldAnimate: true,
    terrainShadows: ShadowMode.ENABLED,
    contextOptions: {
      webgl: {
        //needs to be achieved by the theme canvas.toDataURL set to true
        preserveDrawingBuffer: true
      }
    }
  }
}

export default CESIUM_CONFIG
