import { useState } from 'react'
import { detailStore } from '../store/detailStore'

const useGetDetailPoints = (props: { token: string; plantId: string | null; taskId: string | null }) => {
  const { token, plantId, taskId } = props
  const [taskDetailLoading, setTaskDetailLoading] = useState(false)
  const { setDetailPoints, setDetailDefects, detailFilterPointsStatus } = detailStore

  // useEffect(() => {
  //   const getDetailPoints = async () => {
  //     setTaskDetailLoading(true)
  //     let requestData: any = {
  //       task_id: taskId,
  //       defect_type_id: detailFilterPointsStatus === 8 ? null : detailFilterPointsStatus,
  //       is_deleted: detailFilterPointsStatus === 8 || detailFilterPointsStatus === 9 ? false : null //当不是 无缺陷时，需要传入 is_deleted=false，是无缺陷时，不需要传。
  //     }

  //     let pointConfig: any = {
  //       method: 'GET',
  //       token: token,
  //       url: api.getDefectTypePoints,
  //       params: requestData,
  //       headers: { 'X-TOKEN': `${token}` }
  //     }

  //     axios(pointConfig)
  //       .then(res => {
  //         const points = res.data
  //         setDetailPoints(points)
  //         console.log(res)
  //       })
  //       .catch(error => {
  //         console.log(error)
  //         setTaskDetailLoading(false)
  //       })

  //     setTaskDetailLoading(false)
  //   }

  //   if (token && taskId && plantId) {
  //     getDetailPoints()
  //   }
  // }, [detailFilterPointsStatus, plantId, setDetailPoints, taskId, token])

  return taskDetailLoading
}

export default useGetDetailPoints
