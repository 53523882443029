import { LoadingOutlined, RetweetOutlined } from '@ant-design/icons'
import { Button, Tooltip } from 'antd'
import { observer, useObserver } from 'mobx-react-lite'
import React, { CSSProperties, MouseEvent, useEffect, useRef, useState, WheelEvent } from 'react'
import { detailStore } from '../../store/detailStore'
import { paintCanvas } from '../HistoryBanner/methods'
import './index.scss'

export interface ImageProps {
  w: number
  h: number
  l: number | string
  t: number | string
  s: number
}

const emptyImageProps: ImageProps = {
  w: 0,
  h: 0,
  l: '50',
  t: '50',
  s: 1
}

export const DetailImageViewer =observer( () => {
  const { detailInfraredUrl, detailLightUrl, detailInfraredInfo, detailLightInfo } = detailStore
  console.log(detailInfraredInfo, detailLightInfo)
  const lightImage = useRef<HTMLImageElement>(null)
  const infraredImage = useRef<HTMLImageElement>(null)
  const lightCanvas = useRef<HTMLCanvasElement>(null)
  const infraredCanvas = useRef<HTMLCanvasElement>(null)
  const [infraredLoading, setInfraredLoading] = useState(false)
  const [lightLoading, setLightLoading] = useState(false)
  const [loading, setLoading] = useState(false)

  /* 图片状态 */
  const [imageLoadedState, setImageLoadedState] = useState(emptyImageProps)
  const [imageState, setImageState] = useState(emptyImageProps)

  /* 拖拽 */
  const [distToImageBoundary, setDistToImageBoundary] = useState({ x: 0, y: 0 })
  const [draggable, setDraggable] = useState(false)

  useEffect(() => {
    setLoading(true) // loading遮罩
    const indicator = setTimeout(() => setLoading(false), 3000)
    return () => clearTimeout(indicator)
  }, [detailLightUrl])

  const imageStyle = {
    left: `${imageState.l}${typeof imageState.l === 'string' ? '%' : 'px'}`,
    top: `${imageState.t}${typeof imageState.t === 'string' ? '%' : 'px'}`,
    width: `${imageState.w}px`,
    height: `${imageState.h}px`,
    transform: `translate(-50%, -50%) scale(${imageState.s})`
  }

  useEffect(() => {
    detailLightUrl && setLightLoading(true)
  }, [detailLightUrl])

  useEffect(() => {
    detailInfraredUrl && setInfraredLoading(true)
  }, [detailInfraredUrl])

  const LoadingWrapper = (
    <div className="image-loading-wrapper">
      <LoadingOutlined className="image-loading" />
    </div>
  )

  const imageContainerStyle: CSSProperties = {
    position: 'relative',
    overflow: 'hidden'
  }

  /**
   * @param {HTMLImageElement} node
   * @returns
   */
  const sizing = (node: HTMLImageElement) => {
    const hMax = window.innerHeight
    const wOrigin = node.naturalWidth // 初始的图片宽
    const hOrigin = node.naturalHeight // 初始的图片高度

    const hRatio = hOrigin / hMax
    const size = { w: wOrigin / hRatio, h: hMax }

    const changedState = { w: size.w, h: size.h }
    const state = { ...emptyImageProps, ...changedState }
    setImageLoadedState(state)
    setImageState(state)
  }

  //以可见光为标准做图缩放
  /* 初始化容器大小 */
  const handleLightImageLoaded = () => {
    if (lightImage.current) {
      sizing(lightImage.current)
      setLoading(false)
    }
  }

  /* 重置 */
  const reset = () => {
    setDraggable(false)
    console.log('重置画框', imageLoadedState)
    setImageState(imageLoadedState)
    setLoading(false)
  }

  /* 特殊行为 */
  /* 滚轮缩放 */
  const toScale = (e: WheelEvent<HTMLCanvasElement>) => {
    let scaleDelta = e.deltaY < 0 ? +0.05 : -0.05
    let s = imageState.s * (1 + scaleDelta)
    setImageState(state => {
      const updateState = { ...state, s }
      return updateState
    })
  }

  /* 拖拽开始 */
  const startMove = (e: MouseEvent) => {
    e.preventDefault()
    if (lightImage.current) {
      setDraggable(true)
      setDistToImageBoundary({
        x: e.clientX - lightImage.current.offsetLeft,
        y: e.clientY - lightImage.current.offsetTop
      })
    }
  }

  // 拖拽移动
  const dragging = function (e: MouseEvent) {
    if (!draggable) {
      return
    }
    let l = e.clientX - distToImageBoundary.x
    let t = e.clientY - distToImageBoundary.y
    setImageState(s => ({ ...s, l, t }))
  }

  // 拖拽结束
  const endMove = function () {
    setDraggable(false)
  }

  /* 右键菜单: 终止当前进行的行为 */
  const disableActions = () => {
    setDraggable(false)
  }
  useEffect(() => {
    handleLightImageLoaded()
  }, [detailInfraredInfo])

  const paintInfrared = () => {
    setInfraredLoading(false)
    if (!infraredCanvas.current) return
    infraredCanvas.current.width = imageLoadedState.w
    infraredCanvas.current.height = imageLoadedState.h
    detailInfraredInfo && paintCanvas(detailInfraredInfo!.defects, infraredCanvas.current!, infraredImage.current, imageLoadedState.w, imageLoadedState.h)
  }

  const paintLight = () => {
    setLightLoading(false)
    if (!lightCanvas.current) return
    lightCanvas.current.width = imageLoadedState.w
    lightCanvas.current.height = imageLoadedState.h
    detailInfraredInfo && paintCanvas(detailInfraredInfo!.defects, lightCanvas.current!, lightImage.current, imageLoadedState.w, imageLoadedState.h)
  }

  return useObserver(()=> (
    <div className="detail-viewer-wrapper">
      <div className="image-infrared-container">
        <div className={`g-image-preview-wrapper`} style={imageContainerStyle}>
          <img className={`g-image-preview-image`} style={imageStyle} onLoad={paintInfrared} ref={infraredImage} src={detailInfraredUrl} alt="infrared" />
          {infraredLoading && LoadingWrapper}
          <canvas
            onMouseDown={startMove}
            onMouseMove={dragging}
            onMouseUp={endMove}
            onContextMenu={disableActions}
            onWheel={toScale}
            onClick={e => e.stopPropagation()}
            ref={infraredCanvas}
            className="viewer-canvas infrared"
            style={imageStyle}
          />
        </div>
      </div>
      <div className="image-light-container">
        <div className={`g-image-preview-wrapper`} style={imageContainerStyle}>
          <img className={`g-image-preview-image`} style={imageStyle} onLoad={paintLight} ref={lightImage} src={detailLightUrl} alt="图片" />
          {lightLoading && LoadingWrapper}
          <canvas
            onMouseDown={startMove}
            onMouseMove={dragging}
            onMouseUp={endMove}
            onContextMenu={disableActions}
            onWheel={toScale}
            onClick={e => e.stopPropagation()}
            ref={lightCanvas}
            className="viewer-canvas light"
            style={imageStyle}
          />
        </div>
      </div>
      <Tooltip placement="bottomLeft" title="重置图片定位">
        <Button onClick={reset} className="map-hover-button reset-button">
          <RetweetOutlined />
        </Button>
      </Tooltip>
     
    </div>
  ))
}
)