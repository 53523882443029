import React from 'react'
import { Link } from 'react-router-dom'
import { ROUTERS } from '../../assets/js/constants'
import './index.scss'

export const NotFound = () => {
  return (
    <div className="not-found-wrapper">
      <div></div>
      {/* <h2>“呀！你好像偏离了航线！”</h2> */}
      <p>
        错误: 404，页面不存在。<Link to={ROUTERS.HOME}>返回首页</Link>
      </p>
    </div>
  )
}
