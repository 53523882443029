import { useEffect, useState } from 'react'
import { getDefectTypes, getPatrolCount, getPlantsInfo } from '../service/api'
import { appStore } from '../store/appStore'
import { companyStore } from '../store/companyStore'
import { plantStore } from '../store/plantStore'

const useGetPlants = () => {
  const [plantsLoading, setPlantsLoading] = useState(false)
  const { setPatrolCount } = companyStore
  const { setPlants, setDefectTypes } = plantStore
  const { user } = appStore
  //获取电站相关信息
  //获取缺陷类型信息

  useEffect(() => {
    const getPlants = async () => {
      const defectTypes = await getDefectTypes()
      const plants = await getPlantsInfo()
      const patrolCount = await getPatrolCount(user!.company.id)

      setPatrolCount(patrolCount)
      setPlants(plants)
      setDefectTypes(defectTypes)
      setPlantsLoading(false)
    }
    if (user) {
      setPlantsLoading(true)
      getPlants()
    }
  }, [setDefectTypes, setPatrolCount, setPlants, user])
  return plantsLoading
}

export default useGetPlants
