import { Layout } from "antd";
import { observer, useObserver } from "mobx-react-lite";
import React, { Suspense } from "react";
import HistoryBanner from "../../components/HistoryBanner";
import ImageViewer from "../../components/ImageViewer";
import LiveView from "../../components/LiveView";
import { Loading } from "../../components/Loading/Loading";
import PlantInfoSider from "../../components/PlantInfoSider";
import PlantListSider from "../../components/PlantListSider";
import { connectionStore } from "../../store/connectionStore";
import { mapStore } from "../../store/mapStore";
import { plantStore } from "../../store/plantStore";
import { taskStore } from "../../store/taskStore";
import { Map } from "../Map/Map";
import "./plants.scss";
export const plants = observer(() => {
	const { currentPlant } = plantStore;
	const { currentPoint } = taskStore;
	const { miniMap } = mapStore;

	const { droneInfo, liveRecordsUrl } = connectionStore;

	const { Content } = Layout;
	// const AntSider = Layout.Sider;

	return useObserver(() => (
		<div className="plants-wrapper">
			{miniMap && <ImageViewer />}

			<Layout className="plants-layout">
				{/* <AntSider theme="light" className="sider-wrapper" width={380}> */}
				{/* {!currentPlant ? <PlantListSider /> : <PlantInfoSider />} */}
				{/* </AntSider> */}

				<Content className="plants-content">
					<Suspense fallback={<Loading content="正在加载..." />}>
						{droneInfo.length >= 1 && liveRecordsUrl && <LiveView />}
						<Map
							taskStore={taskStore}
							plantStore={plantStore}
							mapStore={mapStore}
							connectionStore={connectionStore}
						/>
					</Suspense>
				</Content>
				<div className="sidebar">
					{!currentPlant ? <PlantListSider /> : <PlantInfoSider />}
				</div>

				{/* <div>
        <LiveView />
            <Map taskStore={taskStore} plantStore={plantStore} mapStore={mapStore} connectionStore={connectionStore} />
        </div> */}
			</Layout>

			{currentPoint && <HistoryBanner />}
		</div>
	));
});
