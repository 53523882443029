// import { statusPointsGenerator } from './methods'
import { Button, Dropdown, Input, Menu, message, Radio, Space } from "antd";
import { Cartesian3, Entity, Rectangle } from "cesium";
import { observer, useObserver } from "mobx-react-lite";
import React, { useEffect, useRef, useState } from "react";
import { transform } from "../../assets/js/methods";
import {
	getDefectTypePoints,
	getLiveRecordsUrl,
	getTileMap,
} from "../../service/api";
import { connectionStore } from "../../store/connectionStore";
import { reportStore } from "../../store/reportStore";
import {
	DefectTypes,
	DroneInfo,
	LiveRecordsUrl,
	PointsControlFilter,
} from "../../typings/interface";
import CesiumMap from "./CesiumMap";
import { gaoDeMapProvider, googleMapProvider } from "./config";
import "./Map.scss";
import {
	getSpecificEntities,
	initDefaultMap,
	modelFixDom,
	plantCameraCentral,
	setPlantEntities,
} from "./methods";
export const Map = observer(({ taskStore, plantStore, mapStore }: any) => {
	const mapRef = useRef<CesiumMap>(null);

	const { currentPlant, plants, setCurrentPlant, defectTypes } = plantStore;
	const { Search } = Input;
	const { droneInfo, setDrone, drone, setLiveRecordsUrl } = connectionStore;
	const [, setPlantCameraCenter] = useState<Rectangle | undefined>();
	const [currentPlantTiff, setCurrentPlantTiff] = useState<any>(null);
	const { setHistoryPointDefects } = reportStore;
	// const { mapNormalLongitude, mapNormalLatitude, mapVisibleModal, setMapVisibleModal } = mapStore
	const {
		setCurrentPoint,
		currentTask,
		points,
		defects,
		currentPoint,
		setPoints,
		filterPointsStatus,
		setFilterPointsName,
		filterPointsName,
		setFilterPointsStatus,
	} = taskStore;
	const { setMap, googleMapAvailable, map, miniMap, setMiniMap } = mapStore;

	useEffect(() => {
		if (mapRef.current) {
			setMap(mapRef.current);
			initDefaultMap(mapRef.current);
			mapRef.current.addNetworkMapLayer(gaoDeMapProvider);
		}
		return () => {
			setMap(null); // 主动解除地图
		};
	}, [setMap]);

	useEffect(() => {
		if (mapRef.current && googleMapAvailable !== null) {
			googleMapAvailable
				? mapRef.current.addNetworkMapLayer(googleMapProvider)
				: mapRef.current.addNetworkMapLayer(gaoDeMapProvider);
		}
	}, [googleMapAvailable]);

	useEffect(() => {
		if (
			!mapRef.current ||
			!currentPlant ||
			!currentPlant.longitude ||
			!currentPlant.latitude
		) {
			return;
		}
		mapRef.current.flyLongitudeTo(
			+currentPlant.longitude,
			+currentPlant.latitude,
		);
	}, [currentPlant, mapRef]);

	// 加载电厂正射
	useEffect(() => {
		if (!mapRef.current || !currentPlant) {
			return;
		}
		mapRef
			.current!.viewer!.entities.values.filter((e) => e.name === "drone")
			.forEach((l) => mapRef.current!.viewer!.entities.remove(l));

		console.log("map:%c电站加载: ", "color:green", currentPlant.id);
		const tiffUrl = getTileMap(currentPlant!.id);
		console.log(tiffUrl);
		const level = 22;
		mapRef.current!.loadTiff(
			tiffUrl,
			[+currentPlant.longitude, +currentPlant.latitude],
			level,
			setCurrentPlantTiff,
		);
	}, [currentPlant, mapRef]);

	//清除生成plant图标
	useEffect(() => {
		if (currentPlant && mapRef.current) {
			mapRef
				.current!.viewer!.entities.values.filter((e) => e.name === "plant")
				.forEach((l) => mapRef.current!.viewer!.entities.remove(l));
		}
	}, [currentPlant]);

	// 组件卸载
	useEffect(() => {
		const map = mapRef.current;
		return () => {
			map?.removeAllEntity();
			map?.viewer!.destroy();
			setMap(null);
			console.log("%c[Map组件卸载]", "color:orangered");
		};
	}, [setMap]);

	// 加载航点

	useEffect(() => {
		const getFilterPoints = async () => {
			if (!mapRef.current) {
				return;
			}

			message.destroy();
			message.loading({
				content: "航点渲染中...",
				duration: 0,
				key: filterPointsStatus,
			});
			if (filterPointsName !== "") {
				//图文搜索
				const point = await getDefectTypePoints(
					currentTask!.id,
					null,
					filterPointsName,
				);
				await mapRef.current.paintPoints(point, mapRef.current);
				setPoints(point);
			} else {
				console.log(filterPointsStatus);
				const point = await getDefectTypePoints(
					currentTask!.id,
					filterPointsStatus,
				);
				await mapRef.current.paintPoints(point, mapRef.current);
				setPoints(point);
			}

			message.destroy();
			message.success({
				content: "航点渲染完成...",
				key: filterPointsStatus,
			});
		};

		if (currentTask) {
			getFilterPoints();
		}
	}, [currentTask, filterPointsName, filterPointsStatus, setPoints]);

	// 渲染region
	useEffect(() => {
		if (!mapRef.current || !currentPoint) {
			return;
		}
		if (!currentPoint) return;
		const flag = mapRef.current!.viewer!.entities.values.find(
			(item: any) => item.id === currentPoint!.id,
		);
		console.log(flag);
		if (flag) {
			console.log("渲染region");
			mapRef
				.current!.viewer!.entities.values.filter(
					(e: any) => e.polygon && e.description.getValue() === "region",
				)
				.forEach((l) => mapRef.current!.viewer!.entities.remove(l));
			mapRef.current.cesiumPointPolygon(currentPoint);
		}
	}, [currentPoint, defects, filterPointsStatus]);

	useEffect(() => {
		if (currentPlantTiff && !currentPlant) {
			console.log("清理电站底图");
			mapRef.current!.viewer!.imageryLayers.remove(currentPlantTiff);
		}
	}, [currentPlant, currentPlantTiff]);

	// 加载初始地图
	useEffect(() => {
		if (mapRef.current && plants.length && !currentPlant) {
			const viewer = mapRef.current.getViewer();
			if (!viewer) {
				return console.error("cesium viewer error");
			}
			const entities = getSpecificEntities(
				mapRef.current!.viewer!.entities?.values,
				undefined,
				["drone"],
			);
			mapRef.current!.removeSpecificEntities(entities);
			setPlantEntities(plants, viewer);
			// 初始化:设定中心(初始镜头不会拉伸动画) || 非初始化: 拉伸镜头至中心
			setPlantCameraCenter((rect: Rectangle | undefined) => {
				if (rect) {
					viewer.camera.flyTo({
						destination: rect,
						duration: 3,
					});
					return rect;
				} else {
					const centerRect = plantCameraCentral(plants);
					return centerRect;
				}
			});
		}
	}, [plants, currentPlant, map]);

	// 无人机生成
	useEffect(() => {
		if (mapRef.current && droneInfo.length) {
			droneInfo.forEach((item: DroneInfo) => {
				let droneEntityInfos: any = {};
				//查找已存在无人机
				const droneItem = drone.find((s) => +s.user_id === +item.user_id);
				if (!droneItem) {
					//不存在进行生成渲染
					if (item.longitude && item.latitude && item.user_id) {
						// let position = transform(item.longitude * 1, item.latitude * 1);
						let position = [Number(item.longitude), Number(item.latitude)]
						const modelUrl = "./cesium/Assets/Models/drone.glb";
						const droneEntity = mapRef.current!.cesiumModel(
							"无人机",
							item.user_id,
							modelUrl,
							Cartesian3.fromDegrees(position[0], position[1], 0),
							"drone",
						);
						const info = item;
						droneEntityInfos = { ...info, entity: droneEntity };
						mapRef.current!.viewer!.scene.preRender.addEventListener(
							function() {
								modelFixDom(item.user_id, mapRef, droneEntity);
							},
						);
						setDrone([...drone, droneEntityInfos]);
					}
				} else {
					//存在进行修改经纬度
					// let position = transform(item.longitude * 1, item.latitude * 1);
					let position = [Number(item.longitude), Number(item.latitude)]
					droneItem.entity.position = Cartesian3.fromDegrees(
						position[0],
						position[1],
						0,
					);
					mapRef.current!.viewer!.scene.preRender.addEventListener(function() {
						modelFixDom(droneItem.user_id, mapRef, droneItem.entity);
					});
				}
			});
		}
	}, [drone, droneInfo, setDrone]);

	const fiyLive = async (companyId: number, currentPlantId: number) => {
		await getLiveRecordsUrl(companyId, currentPlantId).then(
			(liveUrls: LiveRecordsUrl | null) => {
				console.log(liveUrls);
				if (liveUrls === null) {
					message.error("当前未收到直播流");
				}
				setLiveRecordsUrl(liveUrls);
			},
		);
	};

	const handleClick = (entity: Entity) => {
		if (entity.name === "point") {
			const currentPoint: any = points.find(
				(point: any) => point.id === entity.id,
			);
			if (currentPoint) {
				console.log(currentPoint);
				setCurrentPoint(currentPoint);
				setHistoryPointDefects(null);
			}
		}
		if (entity.name === "plant") {
			const currentPlant: any = plants.find(
				(point: any) => point.id === +entity.id,
			);
			if (currentPlant) {
				setCurrentPlant(currentPlant);
			}
		}
	};

	const onChange = (value: any) => {
		setFilterPointsStatus(value);
		setFilterPointsName("");
	};
	const searchChange = (e: any) => {
		if (e.target.value === "") {
			setFilterPointsStatus(8); //为空时,默认搜索全部
			setFilterPointsName("");
		}
		//setFilterPointsName(e.target.value)
	};
	//搜索
	const onSearch = (value: any) => {
		setFilterPointsName(value);
		setFilterPointsStatus(null);
	};
	// const handleMinClick = () => {
	//   setMiniMap(!miniMap)
	// }

	const filterMenu = (
		<Menu selectedKeys={[filterPointsStatus + ""]}>
			{defectTypes.map((item: DefectTypes) => (
				<Menu.Item key={+item.id} onClick={() => onChange(item.id)}>
					{item.defect_name}
				</Menu.Item>
			))}
		</Menu>
	);
	const renderLookDiv = () => {
		return droneInfo.map((item, index) => (
			<div className="tap" id={item.user_id + ""} key={index}>
				<p>
					<span>飞行速度</span>
					<span>{item.flight_speed.toFixed(1)} m/s</span>
				</p>
				<p>
					<span>垂直速度</span>
					<span>{item.vertical_velocity.toFixed(1)} m/s</span>
				</p>
				<p>
					<span>距离</span>
					<span>{item.distance.toFixed(1)} m</span>
				</p>
				<p>
					<span>高度</span>
					<span>{item.flight_altitude.toFixed(1)} m</span>
				</p>
				<p>
					<span>电量</span>
					<span>{item.battery_power_percentage} %</span>
				</p>

				<p className="triangle">
					<Button onClick={() => fiyLive(item.company_id, item.project_id)}>
						查看
					</Button>
				</p>
			</div>
		));
	};
	return useObserver(() => (
		<div className={`map-wrapper ${miniMap ? "mini" : ""}`}>
			{renderLookDiv()}
			{currentTask && !miniMap && (
				<div className="map-control-filter">
					<Radio.Group value={filterPointsStatus}>
						<Radio.Button
							value={PointsControlFilter.AllPoints}
							onClick={() => onChange(PointsControlFilter.AllPoints)}
						>
							全部
						</Radio.Button>
						<Radio.Button
							value={PointsControlFilter.FinesDefect}
							onClick={() => onChange(PointsControlFilter.FinesDefect)}
						>
							无缺陷
						</Radio.Button>
						<Radio.Button
							value={PointsControlFilter.hasDefect}
							onClick={() => onChange(PointsControlFilter.hasDefect)}
						>
							有缺陷
						</Radio.Button>
						<Dropdown overlay={filterMenu} placement="bottomLeft">
							<Button type="ghost">类型筛选</Button>
						</Dropdown>
					</Radio.Group>
					<Space direction="vertical" className="map-control-search">
						<Search
							placeholder="输入照片名以搜索"
							onChange={searchChange}
							onSearch={onSearch}
						/>
					</Space>
				</div>
			)}

			{/* {points.length >= 1 && currentPoint && (
      <Tooltip placement="bottomRight" title={miniMap ? '返回地图模式' : '进入大图模式'}>
        <button onClick={handleMinClick} className="map-expand-button">
          {miniMap ? <ShrinkOutlined /> : <ArrowsAltOutlined />}
        </button>
      </Tooltip>
    )} */}

			<CesiumMap ref={mapRef} clickCallback={handleClick} />
		</div>
	));
});
