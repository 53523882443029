import { action, makeObservable, observable } from 'mobx'

export interface reportStoreProps {}
export interface ReportCount {
  count_by_stats_type: any[]
  count_by_status: any
  count_by_type: any
  has_defect_num: number
  no_defect_num: number
}

class ReportStore implements reportStoreProps {
  constructor() {
    makeObservable(this)
  }

  @observable pointDefects: any = null
  @observable historyPointDefects: any = null
  @observable reportCount: ReportCount | any = {}
  @action setPointDefects = (pointDefects: any) => {
    this.pointDefects = pointDefects
    console.log('%cstate:已更新pointDefects对象::', 'color:blue', { pointDefects })
  }
  @action setReportCount = (reportCount: ReportCount) => {
    this.reportCount = reportCount
    console.log('%cstate:已更新reportCount对象::', 'color:blue', { reportCount })
  }
  @action setHistoryPointDefects = (history: any) => {
    this.historyPointDefects = history
    console.log('%cstate:已更新historyPointDefects对象::', 'color:blue', { history })
  }
}

export const reportStore: ReportStore = new ReportStore()
