import { message, notification } from "antd";
import Cookies from "js-cookie";
import axios from "axios";
import { getOssUrl } from "../assets/js/methods";
import { appStore } from "../store/appStore";
import {
  GetPlantInfoRes,
  OptimizationStatus,
  PacketType,
  PlantInfo,
  TaskInfo,
  UsersInfo,
} from "../typings/interface";
import { ResWrap } from "./responseInterface";
import { useLocation } from "react-router-dom";

export class ServiceApi {
  static readonly serviceUrl =
    process.env.REACT_APP_API_URL || `${"https://api.setqy.com/api/"}`;
  // `${"http://192.168.1.3:8000/api/"}`; // 本地测试
}

export const api = {
  /**一般 */
  login: "admin/token",
  logout: "user/logout",
  downloadExe: "admin/client-packets",

  //角色
  getRoles: "admin/roles",

  //电站
  getPlants: "admin/projects", //获取公司下所有电站
  //任务
  getTasks: "/patrol/task", //
  upTasks: "/patrol/task", //

  // 人员
  getUser: "admin/users/info", //获取用户信息

  //缺陷类型
  getDefectTypes: "detect/defect_types",

  //巡检信息
  getPatrolCount: "patrol/patrol-count",
  //任务
  //巡检点
  getTaskPoints: "patrol/patrol-points/info",
  //缺陷
  getTaskDefects: "detect/defects",
  getDefectTypePoints: "patrol/patrol-points/status",
  //航点缺陷展示
  getPointDefects: "detect/reports-data",
  //历史缺陷
  getTaskHistoryDefects: "detect/history-defects",
  //直播
  getLiveRecordsUrl: "admin/live_broadcast_records",
  //获取资源信息
  getManagementPackets: "admin/packets",
  //缺陷统计数量
  getReportCount: "detect/reports-count",
  //分享
  getShareToken: "admin/share-token",
};

const INNER_ERROR = "INNER_ERROR";

export const getExe = async () => {
  const res = await axios.get(`${api.downloadExe}?type=1&is_deleted=false`);
  return res.data;
};

export const getAPK = async () => {
  const res = await axios.get(`${api.downloadExe}?type=3&is_deleted=false`);
  return res.data;
};

export const getTileMap = (plantId: number) => {
  // return `${ServiceApi.serviceUrl.replace(
  // 	"api/",
  // 	""
  // )}media/${plantId}/tiff/latest/{z}/{x}/{y}.png`;
  // return getOssUrl(`media/${plantId}/tiff/latest/{z}/{x}/{y}.png`);
  return `https://setqy-test.oss-cn-shanghai.aliyuncs.com/map/${plantId}/{z}/{x}/{y}.png`;
  // return `http://47.100.250.103:8709//media/${plantId}/tiff/latest/{z}/{x}/{y}.png`;
};

export function handleNetworkError(err: any, content: string) {
  // 只打印非内部错误
  if (err.message !== INNER_ERROR) {
    console.error("[Network Error]", { err });
    message.error(content);
  }
}

export function handleInnerError(param: any) {
  console.error("[Inner Error]", { param });
  notification.error({ message: param?.data?.msg, placement: "bottomRight" });
  // 抛出INNER_ERROR防止走入then
  throw new Error(INNER_ERROR);
}
export async function getDefectTypes() {
  const res = await axios.get(`${api.getDefectTypes}`);
  return res.data;
}
export async function getPatrolCount(companyId: number) {
  const res = await axios.get(`${api.getPatrolCount}/${companyId}`);
  return res.data;
}

export function getNotificationConfig(msg: string, rawMsg?: string) {
  const noteConfig = {
    top: 100,
    message: msg,
    description: rawMsg || "",
    duration: 0,
    placement: "bottomRight" as "bottomRight",
  };
  return noteConfig;
}

// [一般] 登录
export const signIn = (username: string, password: string, history: any) => {
  const { setUser, setToken } = appStore;
  const payload = { phone: username, password, client: "4" };
  //client
  //"1": 智飞
  // "2": 寻能
  // "3": 极测
  // "4": 数据中心
  // "5": 管理后台 此时只能由内部人员登录
  axios
    .post(api.login, payload)
    .then(async (res) => {
      console.log(res);
      const data: any = res.data;
      if (data && data.token) {
        setToken(data.token);
        localStorage.setItem("token", data.token);
        Cookies.set("token", data.token, { domain: ".setqy.com" });
        const userInfo = await syncBasic();
        localStorage.setItem("user_id", userInfo.id + "");
        localStorage.setItem("company_id", data.company_id + "");
        console.log(userInfo);
        localStorage.setItem("user_phone", userInfo.phone);
        localStorage.setItem("user", JSON.stringify(userInfo));
        setUser(userInfo);

        const searchParams = new URLSearchParams(
          window.location.hash.split("?")[1],
        );
        const params = searchParams.get("redirect_to");
        if (params !== null) {
          window.location.href = params;
        } else {
          history.push("/home");
        }
      } else {
        message.error(`登录失败：${data}`);
      }
    })
    .catch((err) => handleNetworkError(err, "登录失败,服务异常"));
};

export const signOut = () => {
  const { setUser } = appStore;
  Cookies.remove("token", { domain: ".setqy.com" });
  localStorage.removeItem("token");
  localStorage.removeItem("user_id");
  localStorage.removeItem("user");
  localStorage.removeItem("user_phone");
  localStorage.removeItem("email");
  localStorage.removeItem("company_id");
  setUser(null);
  window.location.replace("#/login");
};

export async function syncBasic() {
  const userInfo: ResWrap<UsersInfo> = await axios.get(api.getUser);
  return userInfo.data;
}

// [电站] 请求公司下电站
export async function getPlantsInfo(): Promise<PlantInfo[]> {
  let requestData = {
    is_deleted: false,
  };

  const plants: ResWrap<GetPlantInfoRes> = await axios.get(api.getPlants, {
    params: requestData,
  });
  return plants.data.projects;
}
// [任务+电站]请求任务
export async function getTasksInfo(plantId: number): Promise<TaskInfo[]> {
  let requestData = {
    is_deleted: false,
    optimization_status: OptimizationStatus.succeed,
  };

  const taskInfo: ResWrap<any> = await axios.get(
    `${api.getTasks}?project_id=${plantId}`,
    {
      params: requestData,
    },
  );
  return taskInfo.data.items.filter((item: any) => !item.is_deleted);
}
export async function upTasks(taskId: number, status: OptimizationStatus) {
  const payload = {
    optimization_status: status,
  };
  let res: any = await axios.patch(`${api.upTasks}/${taskId}`, payload);
  return res.data.item;
}
// [图片] 拼接获得图片url

export function getImageUrl(
  currentPlantId: number,
  currentTaskId: number,
  name: string,
) {
  return `${ServiceApi.serviceUrl
    // .replace("/api","",)
    }media/${currentPlantId}/${currentTaskId}/images/${name}`;
}

//任务航点
export async function getTaskPoints(currentTaskId: number) {
  let requestData = {
    task_id: currentTaskId,
  };

  const res = await axios.get(`${api.getTaskPoints}`, {
    params: requestData,
  });
  return res.data;
}
//任务缺陷
export async function getTaskDefects(currentTaskId: number) {
  let requestData = {
    task_id: currentTaskId,
  };

  const res = await axios.get(`${api.getTaskDefects}`, {
    params: requestData,
  });
  return res.data;
}
//任务缺陷
export async function getDefectTypePoints(
  currentTaskId: number,
  defectTypeId?: number | null,
  filterPointsName?: string,
) {
  let requestData = {
    task_id: currentTaskId,
    defect_type_id: defectTypeId === 8 ? null : defectTypeId,
    image_name: filterPointsName,
    is_deleted: defectTypeId === 8 || defectTypeId === 10 ? null : false, //1-7||9  !8||!10
  };

  const res = await axios.get(`${api.getDefectTypePoints}`, {
    params: requestData,
  });
  return res.data;
}

//航点缺陷
export async function getPointDefects(
  currentTaskId: number,
  currentPointId: number | null,
) {
  let requestData = {
    point_id: currentPointId,
    client: "4",
  };

  const res = await axios.get(`${api.getPointDefects}/${currentTaskId}`, {
    params: requestData,
  });
  if (res.data && res.data.length) {
    return res.data[0];
  } else {
    return null;
  }
}

export async function getTaskHistoryDefects(
  taskIds: TaskInfo[],
  waypointId: number | undefined,
) {
  const ids = taskIds.map((item) => item.id);
  let requestData = {
    waypoint_id: waypointId,
    task_ids: ids.length ? ids : null,
  };

  const res = await axios.post(`${api.getTaskHistoryDefects}`, requestData);
  return res.data;
}

//直播
export async function getLiveRecordsUrl(
  companyId: number,
  currentPlantId: number,
) {
  let requestData = {
    company_id: companyId,
    project_id: currentPlantId,
  };

  const res = await axios.get(`${api.getLiveRecordsUrl}`, {
    params: requestData,
  });
  if (res.data.length) {
    return res.data[0];
  } else {
    return null;
  }
}

//获取分享token
export async function getShareToken(currentTaskId: number) {
  let requestData = {
    task_id: currentTaskId,
  };

  const res = await axios.post(`${api.getShareToken}`, requestData);
  return res.data.token;
}
// [资源] 获取资源信息
export async function getManagementPackets(
  currentPlantId: number,
  currentTaskId: number,
  type: PacketType,
) {
  let requestData = {
    project_id: currentPlantId,
    packet_type: type,
    task_id: currentTaskId,
  };

  const res = await axios.get(`${api.getManagementPackets}`, {
    params: requestData,
  });

  return res.data;
}

//下载

export async function downSoft(currentPlantId: number, currentTaskId: string) {
  const newUrl = ServiceApi.serviceUrl.replace("/api/", "");
  const res: any = `${newUrl}/media/${currentPlantId}/${currentTaskId}/report/report.docx`;
  console.log(res);
  return res;
}

export async function getReportCount(currentTaskId: number) {
  const res = await axios.get(`${api.getReportCount}/${currentTaskId}`);
  return res.data;
}
