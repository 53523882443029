import React from 'react'
import { HashRouter, Redirect, Route, Switch } from 'react-router-dom'
import { ROUTERS } from '../assets/js/constants'
import Home from '../pages/home'
import Login from '../pages/login'
import TaskDetail from '../pages/TaskDetail'

export const Router = () => {
  //const routerTo = window.location.hash === '#/task-detail' ? ROUTERS.PLANT_DETAIL : ROUTERS.HOME
  return (
    <HashRouter>
      <Switch>
        <Route path={ROUTERS.LOGIN} component={Login} />
        <Route path={ROUTERS.HOME} component={Home} />
        <Route path={ROUTERS.PLANT_DETAIL} component={TaskDetail} />
        <Redirect from="*" exact to={ROUTERS.HOME} />
      </Switch>
    </HashRouter>
  )
}
