import { LockOutlined, UserOutlined } from "@ant-design/icons";
import { Button, Form, Input } from "antd";
import React, { useState } from "react";
import { useHistory } from "react-router";
import { PASSWORD_RULE } from "../../assets/js/constants";
import { signIn } from "../../service/api";
import "./Login.scss";

interface Props {
  companyName: any;
  appStore: any;
}
export const LoginForm = ({ companyName, appStore }: Props) => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const history = useHistory();

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };

  const handleClickSubmit = () => {
    signIn(username, password, history);
  };

  return (
    <div className="login-main">
      <div className="title">欢迎登录数据查阅中心</div>
      {/* <h1>{companyName || '光伏数据中心'}</h1> */}
      {/* <h2>登录</h2> */}

      <div className="login-form">
        <Form
          name="login"
          initialValues={{ remember: true }}
          onFinish={handleClickSubmit}
          onFinishFailed={onFinishFailed}
        >
          <Form.Item
            name="username"
            className="login-input username"
            rules={[{ required: true, message: "请输入账号" }]}
          >
            <Input
              placeholder="用户名"
              // prefix={<UserOutlined />}
              onChange={(e) => setUsername(e.target.value)}
            />
          </Form.Item>

          <Form.Item
            name="password"
            className="login-input password"
            rules={PASSWORD_RULE}
          >
            <Input.Password
              placeholder="密码"
              // prefix={<LockOutlined />}
              onChange={(e) => setPassword(e.target.value)}
            />
          </Form.Item>

          <Form.Item>
            <Button type="primary" htmlType="submit" className="login-button">
              登录
            </Button>
          </Form.Item>
        </Form>
      </div>

      <div className="login-copyrights"></div>
    </div>
  );
};
