import { action, makeObservable, observable } from 'mobx'
import { DefectTypes, PlantInfo } from '../typings/interface'

class PlantStore {
  constructor() {
    makeObservable(this)
  }
  @observable currentPlant: PlantInfo | null = null //当前进入电站
  @observable plants: PlantInfo[] = [] //公司下的所有电站
  @observable defectTypes: any[] = [] // 缺陷类型
  @action setCurrentPlant = (currentPlant: PlantInfo | null) => {
    this.currentPlant = currentPlant
    console.log('%cstate:已更新currentPlant对象::', 'color:blue', { currentPlant })
  }
  @action setDefectTypes = (types: DefectTypes[]) => {
    this.defectTypes = types
    console.log('%cstate:已更新defectStatus对象::', 'color:blue', { types })
  }

  @action setPlants = (plants: PlantInfo[]) => {
    this.plants = plants
    console.log('%cstate:已更新plants对象::', 'color:blue', { plants })
  }
}

export const plantStore = new PlantStore()
