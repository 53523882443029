import { message } from 'antd'
import axios from 'axios'
import { useEffect, useState } from 'react'
import { api } from '../service/api'
import { detailStore } from '../store/detailStore'

const useGetTaskDetail = (props: { token: string; plantId: string | null; taskId: string | null }) => {
  const { token, plantId, taskId } = props
  const [taskDetailLoading, setTaskDetailLoading] = useState(false)
  const { setDetailCurrentPlant, setDetailCurrentTask, setDetailDefectTypes } = detailStore
  //获取电站相关信息
  //获取缺陷类型信息

  useEffect(() => {
    const getPlantDetail = async () => {
      const requestData = {
        is_deleted: false,
        project_id: plantId
      }
      let config: any = {
        method: 'GET',
        token: token,
        url: api.getPlants,
        params: requestData,
        headers: { 'X-TOKEN': `${token}` }
      }
      axios(config).then(res => {
        const currentPlant = res.data.projects
        if (currentPlant.length) {
          setDetailCurrentPlant(currentPlant[0])
        } else {
          message.error('未存在当前电站信息')
          return
        }
      })
      let typeConfig: any = {
        method: 'GET',
        token: token,
        url: api.getDefectTypes,
        params: requestData,
        headers: { 'X-TOKEN': `${token}` }
      }
      axios(typeConfig)
        .then(res => {
          const defectTypes = res.data
          setDetailDefectTypes(defectTypes)
        })
        .catch(error => {
          console.log(error)
        })
    }
    if (token && plantId) {
      getPlantDetail()
    }
  }, [plantId, setDetailCurrentPlant, setDetailDefectTypes, taskId, token])
  //获取任务信息
  useEffect(() => {
    const getTaskDetail = async () => {
      const requestData = {
        is_deleted: false,
        task_id: taskId,
        project_id: plantId
      }
      let config: any = {
        method: 'GET',
        token: token,
        url: api.getTasks,
        params: requestData,
        headers: { 'X-TOKEN': `${token}` }
      }
      axios(config)
        .then(res => {
          const currentTask = res.data.items
          if (currentTask.length) {
            setDetailCurrentTask(currentTask[0])
          } else {
            message.error('未存在当前任务信息')
            return
          }
          console.log(res)
        })
        .catch(error => {
          console.log(error)
        })
    }
    if (token && taskId && plantId) {
      getTaskDetail()
    }
  }, [plantId, setDetailCurrentTask, taskId, token])

  return taskDetailLoading
}

export default useGetTaskDetail
