import { useEffect, useState } from 'react'
import { getTasksInfo } from '../service/api'
import { plantStore } from '../store/plantStore'
import { taskStore } from '../store/taskStore'

const useGetTasks = () => {
  const [tasksLoading, setTasksLoading] = useState(false)
  const { currentPlant } = plantStore
  const { setTasks } = taskStore
  useEffect(() => {
    const getPlants = async () => {
      const tasks = await getTasksInfo(currentPlant!.id)
      console.log(tasks)
      setTasks(tasks)
      setTasksLoading(false)
    }
    if (currentPlant) {
      setTasksLoading(true)
      getPlants()
    }
  }, [currentPlant, setTasks])
  return tasksLoading
}

export default useGetTasks
