import { LoadingOutlined } from "@ant-design/icons";
import { Divider } from "antd";
import { observer, useObserver } from "mobx-react-lite";
import React, { useEffect, useRef, useState } from "react";
import { getHistoryInfraredUrl, paintCanvas } from "../HistoryBanner/methods";
import "./HistoryPoint.scss";
const thumbWidth = 192;
const thumbHeight = 154;

export const HistoryPoint = observer(({ detailItem }: any) => {
  const [infraredUrl, setInfraredUrl] = useState("");
  const [lightUrl, setLightUrl] = useState("");
  const [infraredLoading, setInfraredLoading] = useState(false);
  const [lightLoading, setLightLoading] = useState(false);
  const [infraredInfo, setInfraredInfo] = useState<any>(null);
  const [lightInfo, setLightInfo] = useState<any>(null);
  const infraredRef = useRef<HTMLImageElement>(null);
  const lightRef = useRef<HTMLImageElement>(null);
  const reportCanvasRef = useRef<HTMLCanvasElement>(null);
  const reportLightRef = useRef<HTMLCanvasElement>(null);

  // if (taskHistoryLoading) {
  //   return useObserver(() => <Loading content="正在获取历史航点缺陷..." />)
  // }

  useEffect(() => {
    if (detailItem) {
      const { infraredImage, lightImage, infraredUrl, lightUrl } =
        getHistoryInfraredUrl(
          detailItem,
          detailItem!.project_id,
          detailItem!.task_id
        );
      setInfraredInfo(infraredImage);
      setLightInfo(lightImage);
      const imagePackage = {
        infraredInfo: infraredImage,
        lightInfo: lightImage,
      };
      console.log({ imagePackage });

      console.log("infraredUrl", infraredUrl);
      setInfraredUrl(infraredUrl);
      setLightUrl(lightUrl);
    }
  }, [detailItem]);

  useEffect(() => {
    lightUrl && setLightLoading(true);
  }, [lightUrl]);

  useEffect(() => {
    infraredUrl && setInfraredLoading(true);
  }, [infraredUrl]);

  const paintInfrared = () => {
    setInfraredLoading(false);
    console.log(infraredInfo);
    infraredInfo &&
      paintCanvas(
        infraredInfo.defects,
        reportCanvasRef.current!,
        infraredRef.current,
        thumbWidth,
        thumbHeight
      );
  };

  const paintLight = () => {
    setLightLoading(false);
    infraredInfo &&
      paintCanvas(
        infraredInfo.defects,
        reportLightRef.current!,
        lightRef.current,
        thumbWidth,
        thumbHeight
      );
  };

  const LoadingWrapper = (
    <div className="image-loading-wrapper">
      <LoadingOutlined className="image-loading" />
    </div>
  );
  return useObserver(() => (
    <div className="current-history-info">
      <div className="current-task-name">
        <p>任务名称:{detailItem?.task_name}</p>
      </div>
      <Divider style={{ margin: 0 }} />
      <div className="current-history-wrapper">
        <div className="current-history-image">
          <a href={infraredUrl} target="_blank" rel="noopener noreferrer">
            <div className="current-history-infrared">
              <div
                className="report-image-wrapper"
                style={{
                  height: thumbHeight,
                  width: thumbWidth,
                }}
              >
                <canvas
                  width={thumbWidth}
                  height={thumbHeight}
                  ref={reportCanvasRef}
                  className="report-detail-canvas"
                />
                {infraredLoading && LoadingWrapper}
                <img
                  // src={infraredUrl}
                  src={detailItem.oss_url[0]}
                  loading="lazy"
                  ref={infraredRef}
                  onLoad={paintInfrared}
                  alt="infrared"
                />
              </div>
            </div>
          </a>
          <a href={lightUrl} target="_blank" rel="noopener noreferrer">
            <div className="current-history-light">
              <div
                className="report-image-wrapper"
                style={{
                  height: thumbHeight,
                  width: thumbWidth,
                }}
              >
                <canvas
                  width={thumbWidth}
                  height={thumbHeight}
                  ref={reportLightRef}
                  className="report-detail-canvas"
                />
                {lightLoading && LoadingWrapper}
                <img
                  src={detailItem.oss_url[1]}
                  // src={lightUrl}
                  loading="lazy"
                  ref={lightRef}
                  onLoad={paintLight}
                  alt="infrared"
                />
              </div>
            </div>
          </a>
        </div>
        <div className="current-history-name">
          <span>{infraredInfo?.name || ""}</span>
          <span>{lightInfo?.name}</span>
        </div>
      </div>
    </div>
  ));
});
