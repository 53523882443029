import { CompanyInfo, Drone } from '../../typings/interface'

export const PATROL_COUNT = {
  project_name: '',
  patrol_date: ''
}

export const COMPANY_INFO: CompanyInfo = {
  currentCapacityCountLimit: 0,
  adminiName: '',
  adminiPhone: 0,
  contactAddress: '',
  contactEmail: '',
  endTime: '',
  id: 0,
  name: '',
  currentPlantCount: 0,
  registerTime: '',
  remainingTime: 0,
  startTime: '',
  topLimit: 0,
  userNum: 0
}

export const POINT_STATUS_CESIUM_COLOR = new Map([
  [0, '#9f9f9f'],
  [1, '#fa8c16'],
  [2, '#008eff'],
  [3, '#52c41a']
])

export const PASSWORD_RULE = [{ required: true, message: '请输入密码' }]
// 用于标记其他类型的缺陷数据

export const REPORT_STATUS_CODE = ['未生成', '等待生成', '生成中', '生成失败', '等待更新', '可下载']

export const ROUTERS = {
  LOGIN: '/login',
  HOME: '/home',
  PLANTS: '/home/plants',
  PLANT: '/home/plant',
  MEMBERS: '/home/members',
  USER: '/home/user',
  PLANT_DETAIL: '/task-detail'
}

export const DRONE_CONFIG: Drone = {
  id: null,
  modelUrl: './cesium/Assets/Models/drone.glb',
  name: '无人机',
  longitude: 0,
  latitude: 0,
  altitude: 5
}

export const aa = [
  {
    battery_power_percentage: '155%',
    client: '4',
    company_id: 1,
    distance: '155m',
    flight_altitude: '155m',
    flight_speed: '155km/h',
    key: '一个字符串用来拼接直播地址',
    latitude: 30.775658,
    longitude: 120.150856,
    project_id: 2,
    project_name: '2-号电站',
    user_id: 1,
    user_phone: '18811111111',
    user_state: '用户 18811111111 在线',
    vertical_velocity: '155km/h'
  },
  {
    battery_power_percentage: '155%',
    client: '4',
    company_id: 1,
    distance: '155m',
    flight_altitude: '155m',
    flight_speed: '155km/h',
    key: '一个字符串用来拼接直播地址',
    latitude: 39.27565822,
    longitude: 121.1508561,
    project_id: 4,
    project_name: '2-号电站',
    user_id: 1,
    user_phone: '18811111111',
    user_state: '用户 18811111111 在线',
    vertical_velocity: '155km/h'
  }
]
