import { ReloadOutlined } from "@ant-design/icons";
import { Tooltip } from 'antd';
import { observer, useObserver } from "mobx-react";
import React from "react";
import "./LiveReload.scss";


interface Props {
 flvPlayer: any | null;
}

export const LiveReload = observer((props: Props) => {
  const { flvPlayer } = props;

  function reloadLive() {
    flvPlayer!.pause();
    flvPlayer!.unload();
    flvPlayer!.load();
    flvPlayer!.play();
  }

  return useObserver(() => (
    <div className="live-reload-wrapper" onClick={reloadLive}>
       <Tooltip title="刷新">  <ReloadOutlined /></Tooltip>
    </div>
  ));
});
