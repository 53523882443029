import { action, makeObservable, observable } from 'mobx'

class MapStore {
  constructor() {
    makeObservable(this)
  }
  @observable map: any | null = null
  @observable googleMapAvailable: boolean = true
  @observable miniMap: boolean = false //切换
  


  @observable infraredUrl: string = "" 
  @observable lightUrl: string = "" 
  
  @observable infraredInfo: any = null
  @observable lightInfo: any = null
  
  @action setInfraredInfo = (info: any) => {
    this.infraredInfo = info
  }
  @action setLightInfo = (info: any) => {
    this.lightInfo = info
  }
  @action setInfraredUrl = (url: string) => {
    this.infraredUrl = url
  }
  @action setLightUrl = (url: string) => {
    this.lightUrl = url
  }



  @action setMiniMap = (flag: boolean) => {
    this.miniMap = flag
  }

  @action setMap = (map: any) => {
    this.map = map
  }
  @action setGoogleMapAvailable = (status: boolean) => {
    this.googleMapAvailable = status
  }
}

export const mapStore = new MapStore()
