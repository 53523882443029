import { Button, message, Popover } from "antd";
import copy from "copy-to-clipboard";
import { Base64 } from "js-base64";
import { observer, useObserver } from "mobx-react";
import { QRCodeSVG } from "qrcode.react";
import React from "react";
import { ROUTERS } from "../../assets/js/constants";
import { getCurrentUserPermission } from "../../assets/js/methods";
import {
  downSoft,
  getManagementPackets,
  getShareToken,
} from "../../service/api";
import { appStore } from "../../store/appStore";
import { PacketType, RolesType } from "../../typings/interface";
import "./TaskList.scss";
export const TaskList = observer(
  ({ taskStore, detailStore, plantStore, setTaskShareUrl }: any) => {
    const { tasks, currentTask, setCurrentTask } = taskStore;
    const { currentPlant } = plantStore;
    const { user } = appStore;

    const [shareUrl, setShareUrl] = React.useState("");

    const getShare = async (currentTaskId: number) => {
      await getShareToken(currentTaskId).then((token) => {
        //http://139.196.75.44:8711

        //http://localhost:7799
        const base64Token = Base64.encode(token);
        console.log(base64Token);
        // const api = "http://139.196.75.44:8711"; //ServiceApi.serviceUrl.replace('/api/', '')
        const api = "https://cloud.setqy.com";
        // const api = "http://localhost:3000";
        // history.push({ pathname: ROUTERS.PLANT_DETAIL, state: { taskID: currentTaskId, token: token } })
        const url = `${api}/#${ROUTERS.PLANT_DETAIL}?plantid=${
          currentPlant!.id
        }&&taskid=${currentTaskId}&&token=${base64Token}`;
        setShareUrl(url);
        try {
          copy(url);
          message.success("复制成功,可直接粘贴分享给他人");
        } catch (error) {
          message.error("复制失败,请从文本中粘贴");

          setTaskShareUrl(url);
          console.log(error);
        }
        console.log(url);
      });
    };
    const downLoadReport = async (taskId: number) => {
      await getManagementPackets(
        +currentPlant!.id,
        taskId,
        PacketType.report
      ).then(async (res) => {
        if (res === null) {
          message.error("当前任务暂无报告");
        } else {
          message.loading({
            content: "报告下载中",
            duration: 0,
            key: taskId,
          });
          console.log(res);
          let DownloadLink: any = document.createElement("a");
          DownloadLink.style = "display: none"; // 创建一个隐藏的a标签
          DownloadLink.download = res;
          DownloadLink.href = res;
          document.body.appendChild(DownloadLink);
          DownloadLink.click(); // 触发a标签的click事件
          document.body.removeChild(DownloadLink);
          message.success({
            content: "报告下载成功",
            key: taskId,
          });

          // await downSoft(currentPlant!.id, taskId + "")
          // 	.then((downloadSkywayUrl: any) => {
          // 		console.log(downloadSkywayUrl);
          // 		let DownloadLink: any = document.createElement("a");
          // 		DownloadLink.style = "display: none"; // 创建一个隐藏的a标签
          // 		DownloadLink.download = downloadSkywayUrl.data.url;
          // 		DownloadLink.href = downloadSkywayUrl.data.url;
          // 		document.body.appendChild(DownloadLink);
          // 		DownloadLink.click(); // 触发a标签的click事件
          // 		document.body.removeChild(DownloadLink);
          // 		message.success({
          // 			content: "报告下载成功",
          // 			key: taskId,
          // 		});
          // 	})
          // 	.catch((error) => {
          // 		console.log(error);
          // 		message.error({
          // 			content: "报告下载失败",
          // 			key: currentPlant.id,
          // 		});
          // 	});
        }
      });
    };
    return useObserver(() => (
      <div className="tasks-list-wrapper">
        <p className="tasks-title">巡检历史</p>
        <div className="tasks-box">
          {tasks.map((task: any) => (
            <div
              key={task.id}
              className={[
                "list-item",
                currentTask && currentTask.id === task.id ? "selected" : "",
              ].join(" ")}
            >
              <div className="tasks-item">
                <div
                  className="tasks-info"
                  onClick={() => {
                    setCurrentTask(task);
                    setTaskShareUrl(null);
                  }}
                >
                  <div className="tasks-name">{task.name}</div>
                  <div className="tasks-date">
                    缺陷航点/全部航点数：{task.defect_count[0] ?? 0}/
                    {task.defect_count[1] ?? 0}
                  </div>
                  <div className="tasks-date">{task.create_time}</div>
                </div>
                <div className="tasks-share">
                  {getCurrentUserPermission(
                    RolesType.HistoryData,
                    user!.permissions
                  ) && (
                    <Popover
                      placement="top"
                      title="二维码"
                      content={<QRCodeSVG value={shareUrl} level="L" />}
                      trigger="click"
                    >
                      <Button onClick={() => getShare(task.id)}>
                        分享任务
                      </Button>
                    </Popover>
                  )}

                  <Button onClick={() => downLoadReport(task.id)}>
                    下载报告
                  </Button>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    ));
  }
);
