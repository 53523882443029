import { action, makeObservable, observable } from 'mobx'
import { Defect, PointInfo, TaskInfo } from '../typings/interface'

class TaskStore {
  constructor() {
    makeObservable(this)
  }
  @observable tasks: TaskInfo[] = [] //任务
  @observable currentTask: TaskInfo | null = null //当前任务
  @observable points: PointInfo[] = []
  @observable currentPoint: PointInfo | null = null //当前航点
  @observable defects: Defect[] = []
  @observable filterPointsStatus: number = 8
  @observable filterPointsName: string = ''

  @action setTasks = (tasks: TaskInfo[]) => {
    this.tasks = tasks
    console.log('%cstate:已更新tasks对象::', 'color:blue', { tasks })
  }
  @action setCurrentTask = (currentTask: TaskInfo | null) => {
    this.currentTask = currentTask
    console.log('%cstate:已更新currentTask对象::', 'color:blue', { currentTask })
  }
  @action setCurrentPoint = (currentPoint: PointInfo | null) => {
    this.currentPoint = currentPoint
    console.log('%cstate:已更新currentPoint对象::', 'color:blue', { currentPoint })
  }
  @action setFilterPointsStatus = (status: number | any) => {
    this.filterPointsStatus = status
    console.log('%cstate:已更新filterPointsStatus对象::', 'color:blue', { status })
  }

  @action setFilterPointsName = (name: string | any) => {
    this.filterPointsName = name
    console.log('%cstate:已更新filterPointsname对象::', 'color:blue', { name })
  }

  @action setPoints = (points: PointInfo[]) => {
    this.points = points
    console.log('%cstate:已更新points对象::', 'color:blue', { points })
  }
  @action setDefects = (defects: Defect[]) => {
    this.defects = defects
    console.log('%cstate:已更新defects对象::', 'color:blue', { defects })
  }
}

export const taskStore = new TaskStore()
