import {
  PoweroffOutlined,
  AndroidOutlined,
  ExclamationCircleOutlined,
  CloudDownloadOutlined,
} from "@ant-design/icons";
import { Button, Modal, Tooltip } from "antd";
import { size } from "lodash";
import React from "react";
import logo from "../../assets/image/logo.png";
import { getAPK, getExe, signOut } from "../../service/api";

import "./Header.scss";
export const Header = () => {
  const { confirm } = Modal;

  const showCofirm = () => {
    confirm({
      title: "确定退出登录吗？",
      icon: <ExclamationCircleOutlined />,
      content: "点击确定退出登录。",
      okText: "确认",
      cancelText: "取消",
      onOk: () => signOut(),
      onCancel: () => { },
    });
  };

  const downloadExe = () => {
    confirm({
      title: "确定下载最新版本吗？",
      icon: <ExclamationCircleOutlined />,
      content: "点击确定下载最新版。",
      okText: "确认",
      cancelText: "取消",
      onOk: () =>
        getExe().then((res) => {
          if (res && res.length > 0) {
            const a = document.createElement("a");
            const href = res[0]?.file_url;
            a.href = href;
            a.download = "setqy";
            a.style.display = "none";
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
          }
        }),
      onCancel: () => { },
    });
  };

  const downloadApk = () => {
    confirm({
      title: "确定下载安卓APK吗？",
      icon: <ExclamationCircleOutlined />,
      content: "点击确定下载APK。",
      okText: "确认",
      cancelText: "取消",
      onOk: () =>
        getAPK().then((res) => {
          if (res && res.length > 0) {
            const a = document.createElement("a");
            const href = res[0]?.file_url;
            a.href = href;
            a.download = "xunneng";
            a.style.display = "none";
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
          }
        }),
      onCancel: () => { },
    });
  };

  return (
    <div className="header-wrapper">
      <img src={logo} alt="logo" />
      <div className="title">数据查阅中心</div>
      <p className="jump">
        <a href="https://community.setqy.com/">帮助中心</a>
      </p>
      <p className="jump">
        <a href="https://community.setqy.com/helps/">使用手册</a>
      </p>
      <p className="jump">
        <a href="https://community.setqy.com/cases/">案例论坛</a>
      </p>
      <p className="jump">
        <a href="https://community.setqy.com/contact/">联系我们</a>
      </p>
      <p>
        {localStorage.getItem("user")
          ? JSON.parse(localStorage.getItem("user")!).name
          : ""}
      </p>
      {localStorage.getItem("user") && (
        <Button
          type="text"
          size="small"
          onClick={showCofirm}
          style={{
            padding: 0,
            color: "#177de1",
          }}
        >
          退出
        </Button>
      )}
      <div className="btn-group">
        <Tooltip placement="bottom" title="下载PC端">
          <Button
            icon={<CloudDownloadOutlined />}
            size="middle"
            style={{
              width: 36,
              height: 36,
            }}
            onClick={downloadExe}
          />
        </Tooltip>
        <Tooltip placement="bottom" title="下载安卓端">
          <Button
            icon={<AndroidOutlined />}
            size="middle"
            style={{
              width: 36,
              height: 36,
            }}
            onClick={downloadApk}
          />
        </Tooltip>
        {/*
        <Button
          icon={<PoweroffOutlined style={{ color: "#fff" }} />}
          size="middle"
          onClick={showCofirm}
          style={{
            borderRadius: 4,
            background: "#177de1",
            borderColor: "#177de1",
            width: 36,
            height: 36,
            cursor: "pointer",
          }}
        />
        */}
      </div>
    </div>
  );
};
