import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'

export const Guard = () => {
  const history = useHistory()

  // 注册token到store(对于自动登录的用户)
  useEffect(() => {
    if (!localStorage['token'] && window.location.hash !== '#/login') {
      history.push('/login')
    }
  }, [history, history.location.pathname])
  return <></>
}
