import { Entity } from "cesium";

export interface User {
  id: number;
  name: string;
  token: string;
  company: string;
  phone: string;
  email: string;
}

export enum UpdateSelectType {
  updatePeople = "1",
  password = "2",
  addCompany = "3",
  addPeople = "4",
  companyUpdate = "5",
}
export interface Company {
  id: number;
  name: string;
}
export interface ResWrap<T> {
  data: T;
  success: boolean;
  code?: number;
}
export interface UsersInfo {
  company_id: string;
  company: Company;
  create_time: string;
  id: number;
  name: string;
  permissions: Permissions[];
  phone: string;
  role: Role;
  user_level: string;
  email?: string;
}
export interface Role {
  id: number;
  name: string;
}
export interface AddCompany {
  selectPlantCount: number;
  adminiName: string;
  adminiPhone: string;
  remember: boolean;
  username: string;
  selectUserCount: string;
  selectSizeCount: string;
}

export interface AddUser {
  // company_id:number; //:公司id
  password: string;
  select: string; //:0代表内部成员 1代表是其他公司管理员 2其他公司普通成员
  adminiName: string; //: 名字
  adminiPhone: number;
  avatar?: string; //:非必填 "头像二进制数据"
  role: string; //:0超级管理员 1代表是管理员 2成员
}

export interface GetPlantInfoRes {
  projects: PlantInfo[];
}
export interface TaskInfo {
  id: number;
  project_id: number;
  name: string;
  task_type: 4;
  is_deleted: boolean;
  start_time: string;
  end_time: string;
  create_time: string;
  camera_model: string;
  status: {
    algo_status: null;
    flight_status: null;
  };
}
export interface GetPlantRes {
  projects: GetPlantInfoRes[];
  total: number;
}
export interface PlantInfoAll {
  list: PlantInfo[];
  total: number;
}
export interface DefectTypes {
  id: number;
  defect_type: number;
  defect_name: string;
  defect_color: string;
}
export enum PointStatus {
  noDefect = 0, //上传 没有缺陷
  hasDefect = 1, //上传 有缺陷
  toUpDefect = 2, //未上传 有缺陷
  toUpNoDefect = 3, //未上传 没有缺陷
  PartUntreated = 4, //部分未处理
  allTreated = 5, //全部已经处理
}

export interface PlantInfo {
  avatar: null | string;
  brief: null | string;
  companyId: number;
  create_time: string;
  creatorName: null | string;
  currentCapacityCountLimit: null | number;
  id: number;
  is_deleted: boolean;
  latitude: number;
  longitude: number;
  name: string;
  station_type: string;
}
export enum PacketType {
  map = "1",
  kks = "2",
  line = "3",
  report = "4",
}
export interface UpDataUser {
  adminiName: string;
  adminiPhone: number;
  select: string;
  role: string; //:0超级管理员 1代表是管理员 2成员
}

export interface CompanyInfo {
  currentCapacityCountLimit?: number;
  adminiName?: string;
  adminiPhone?: number;
  contactAddress?: string;
  contactEmail?: string;
  endTime?: string;
  id: number;
  name?: string;
  currentPlantCount?: number;
  registerTime?: string;
  remainingTime?: number;
  startTime?: string;
  topLimit?: number;
  userNum?: number;
  optimization?: boolean;
}

export interface SoftwareReleaseListType {
  name: string;
  type: "jice" | "zhifei" | "xunneng";
}
export interface Permissions {
  id: number;
  name: string;
  parent_id: null | number;
}
export enum OptimizationStatus {
  defect = "0", //默认
  pending = "1", //待优化
  execution = "2", //处理中
  error = "3", //优化失败
  succeed = "4", //优化成功
}

export const SOCKET_EVENT = {
  JOIN_ROOM: "join_room",
  LIVE_STATUS: "live_message",
  LEAVE_ROOM: "leave_room",
};

export enum PointsControlFilter {
  AllPoints = 8,
  hasDefect = 9,
  FinesDefect = 10,
}

export interface LiveRecordsUrl {
  id: number;
  key: string;
  company_id: number;
  start_time: string;
  end_time: null | string;
  live_link: string;
  flv_link: string;
}
export interface Drone {
  id: number | null;
  name: string;
  modelUrl: string;
  latitude: number;
  longitude: number;
  altitude: number;
  // status: DroneStatusInfo;
  entity?: Entity;
}

export interface DroneInfo {
  battery_power_percentage: string;
  client: string;
  company_id: number;
  distance: number;
  flight_altitude: number;
  flight_speed: number;
  key: string;
  project_id: number;
  project_name: string;
  user_id: number;
  user_phone: string;
  user_state: string;
  vertical_velocity: number;
  latitude: number;
  longitude: number;
  sender_state: string;
}
export enum RolesType {
  DataCenterLiveBroadcast = "实时直播",
  HistoryData = "历史数据",
}

export type LensType = "ZXT2A13" | "ZXT2A19" | "ZXT2B13" | "H20T2";
export interface PointInfo {
  index: any;
  lens?: LensType;
  defectStatus?: any; //0 已上传没有缺陷  1 已上传有缺陷 2 未上传没有缺陷 3 未上传有缺陷 // 4 未处理缺陷 5 已处理缺陷
  defects?: any;
  service_create_time?: string;
  selectType?: string;
  pathJson: string;
  id: number | null;
  ids: number;
  name: string;
  status: number;
  waypoint_id?: number;
  longitude: number;
  latitude: number;
  ratio?: any[];
  size: any[];
  height?: number;
  altitude: number;
  brief: string;
  light_image_name: string;
  infrared_image_name: string;
  create_time: any;
  update_time: string;
  packet_id: number;
  task_id?: number;
  filePath?: string;
  path?: string;
  cameraType: any;
  imageUrl?: any[];
  //createPath: string;
  defectLength?: number;
  createLightPath: string;
  createInfraredPath: string;
}
export interface Defect {
  defect_status: any;
  brief: any;
  patrol_point_id: number | null;
  id: string;
  name: string;
  type?: number; //! 这是个老字段;可能已弃用; 但依然存在可能的依赖
  light_image_name: string;
  infrared_image_name: string;
  longitude: number;
  latitude: number;
  altitude: number;
  region: string;
  light_region: string;
  defect_type: any;
  project_id: number;
  create_time: string;
  update_time: string;
  task_id?: number;
  task_name?: string;
  point_id?: number;
  flag?: boolean | string;
  packet_id?: number;
  defectStatus?: any;
  state?: any;
  status?: any;
  currentStatus?: any;
  tag: string | number;
  image_id?: any;
  is_deleted?: boolean;
  image_type?: string; //"0" 未知 "1" 可见光  "2" 红外 "3" 变焦   "4" 广角
}
export interface PvCesiumPoint extends PointInfo {
  color: string;
  count: number;
  defects: Defect[];
  region?: any;
  position: { x: number; y: number; z: number };
}

export type EntityCategory =
  | "label"
  | "plant"
  | "point"
  | "line"
  | "connectLine"
  | "hangar"
  | "drone"
  | "standby"
  | "polygon"
  | "region"
  | "kks"
  | "pointRegion";
