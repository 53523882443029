import { useEffect, useState } from 'react'
import { plantStore } from '../store/plantStore'
import { taskStore } from '../store/taskStore'

const useGetPoints = () => {
  const [pointsLoading, setPointsLoading] = useState(false)
  const { currentTask, setPoints, setDefects, filterPointsStatus } = taskStore
  const { currentPlant } = plantStore
  useEffect(() => {
    const getPoints = async () => {
      // setPointsLoading(true)
      // const point = await getDefectTypePoints(currentTask!.id, filterPointsStatus)
      // setPoints(point)
      // setPointsLoading(false)
    }

    if (currentTask && currentPlant) {
      getPoints()
    }
  }, [currentPlant, currentTask, filterPointsStatus, setPoints])
  return pointsLoading
}

export default useGetPoints
