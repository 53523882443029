import { Permissions } from "../../typings/interface";
const OSS = require("ali-oss");
export function dashToCamelCase(str: string): string {
  const newStr = str.replace(
    /(_[a-z])/gi,
    (a, b) => a[1] && a[1].toUpperCase(),
  );
  return newStr;
}
export const passwordRule = [
  { required: true, message: "请输入密码" },
  // { max: 16, message: "密码长度不能大于16" },
  // { min: 6, message: "密码长度不能小于6" },
];
export function dateDays(date1: string, date2: string) {
  var sdate: any = new Date(date1);
  var now: any = new Date(date2);
  var days: any = now.getTime() - sdate.getTime();
  var day = parseInt(days / (1000 * 60 * 60 * 24) + "");
  return day;
}
export const compareTime = (beginTime: string) => {
  var beginTimes = beginTime.replace(/-/g, "/");

  return Date.parse(beginTimes);
};

/**
 * 单点坐标纠偏
 */
var pi = 3.14159265358979324;
/**
 * 地球坐标转换为火星坐标，即WGS84（国际通用）转为GCJ02坐标系
 * 适用于腾讯地图、高德（阿里）地图或谷歌地图
 */
export function transform(wgLon: number, wgLat: number) {
  /**
   * 单点坐标纠偏
   */
  var pi = 3.14159265358979324;
  var a = 6378245.0;
  var ee = 0.00669342162296594323;
  var x_pi = (3.14159265358979324 * 3000.0) / 180.0;

  var latlng = [];
  if (outOfChina(wgLat, wgLon)) {
    latlng[0] = wgLon;
    latlng[1] = wgLat;
    return latlng;
  }
  var dLat = transformLat(wgLon - 105.0, wgLat - 35.0);
  var dLon = transformLon(wgLon - 105.0, wgLat - 35.0);
  var radLat = (wgLat / 180.0) * pi;
  var magic = Math.sin(radLat);
  magic = 1 - ee * magic * magic;
  var sqrtMagic = Math.sqrt(magic);
  dLat = (dLat * 180.0) / (((a * (1 - ee)) / (magic * sqrtMagic)) * pi);
  dLon = (dLon * 180.0) / ((a / sqrtMagic) * Math.cos(radLat) * pi);
  latlng[0] = wgLon + dLon;
  latlng[1] = wgLat + dLat;

  return latlng;
}

function outOfChina(lat: number, lon: number) {
  if (lon < 72.004 || lon > 137.8347) return true;
  if (lat < 0.8293 || lat > 55.8271) return true;
  return false;
}

function transformLat(x: number, y: number) {
  var ret =
    -100.0 +
    2.0 * x +
    3.0 * y +
    0.2 * y * y +
    0.1 * x * y +
    0.2 * Math.sqrt(Math.abs(x));
  ret +=
    ((20.0 * Math.sin(6.0 * x * pi) + 20.0 * Math.sin(2.0 * x * pi)) * 2.0) /
    3.0;
  ret +=
    ((20.0 * Math.sin(y * pi) + 40.0 * Math.sin((y / 3.0) * pi)) * 2.0) / 3.0;
  ret +=
    ((160.0 * Math.sin((y / 12.0) * pi) + 320 * Math.sin((y * pi) / 30.0)) *
      2.0) /
    3.0;
  return ret;
}

function transformLon(x: number, y: number) {
  var ret =
    300.0 +
    x +
    2.0 * y +
    0.1 * x * x +
    0.1 * x * y +
    0.1 * Math.sqrt(Math.abs(x));
  ret +=
    ((20.0 * Math.sin(6.0 * x * pi) + 20.0 * Math.sin(2.0 * x * pi)) * 2.0) /
    3.0;
  ret +=
    ((20.0 * Math.sin(x * pi) + 40.0 * Math.sin((x / 3.0) * pi)) * 2.0) / 3.0;
  ret +=
    ((150.0 * Math.sin((x / 12.0) * pi) + 300.0 * Math.sin((x / 30.0) * pi)) *
      2.0) /
    3.0;
  return ret;
}

export const getCurrentUserPermission = (type: any, roles: Permissions[]) => {
  if (roles) {
    const flag = roles.some((item: any) => item.description === type);
    return flag;
  } else {
    return false;
  }
};

export const getOssUrl = (path: string) => {
  const client = new OSS({
    region: "oss-cn-shanghai",
    // endpoint: "oss-cn-shanghai.aliyuncs.com",
    accessKeyId: "LTAI5tDpKoFfpa2ewQkcLWQE",
    accessKeySecret: "yGOdJOCITYxLDSeyMLmbejY9NthXgv",
    bucket: "setqy-test",
  });
  return client.signatureUrl(path);
};
