import { action, makeObservable, observable } from 'mobx'
import { DroneInfo, LiveRecordsUrl } from '../typings/interface'

// import { DroneStatusInfo } from '../typings/interface'

class ConnectionState {
  constructor() {
    makeObservable(this)
  }

  @observable socket: SocketIOClient.Socket | undefined
  @observable droneInfo: DroneInfo[] = [] // 无人机状态信息
  @observable liveRecordsUrl: LiveRecordsUrl | null = null // 无人机直播流
  @observable drone: any[] = [] // 无人机
  @observable fliedSum: number | null = null

  @observable backHomeSubState: number | null = null //无人机返回指令
  @observable backHomeBackHomeIndex: number = 0 //无人机返回指令 监听次数

  @action setBackHomeBackHomeIndex = (index: number) => {
    this.backHomeBackHomeIndex = index
  }
  @action setLiveRecordsUrl = (url: LiveRecordsUrl | null) => {
    this.liveRecordsUrl = url
  }
  @action setBackHomeSubState = (subState: number) => {
    this.backHomeSubState = subState
  }

  @action setSocket = (socket: SocketIOClient.Socket | undefined) => {
    this.socket = socket
  }

  @action setDroneInfo = (data: DroneInfo[]) => {
    this.droneInfo = data
    console.log('%cstate:已更新droneInfo对象::', 'color:blue', this.droneInfo)
  }

  @action setDrone = (drone: any[]) => {
    this.drone = drone
  }
}

export const connectionStore = new ConnectionState()
